.label-text {
  font-size: 14px;
  color: #000000;
  display: flex;
  align-items: center;
}

.nbfc-text-field {
  height: 0px !important;
}

.nbfc-key-section {
  display: flex;
  align-items: center;
}

.nbfc-key-section p {
  margin-bottom: 0;
}

.nbfc-dialog-edit-btn {
  height: 30px !important;
  margin-top: 10px;
}

.label {
  height: 12px;
  width: 348px;
}

.label .disbursed-amount {
  color: #000000;
  font-family: "Roboto-Regular", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 12px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: fixed;
  top: 0;
  white-space: nowrap;
}
