.wrapper-center-home {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-holder {
  width: 50%;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px; */
}

.amountTxtsm {
  font-family: 'Lato', sans-serif !important;
  font-size: 16px !important;
}

.home-card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 170px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
}

.home-heading {
  font-size: 20px;
  color: rgb(193, 193, 193);
}

.home-card:hover {
  box-shadow: rgba(162, 0, 255, 0.375) 0px 2px 8px 0px;
}

/* .button-ico {
  font-size: 40px !important;
  margin-bottom: 0;
} */

.button-ico {
  width: 60px;
}

.ico-tag {
  color: rgb(108, 108, 108) !important;
  font-weight: 500;
}

.more-tools-heading {
  color: purple;
  font-size: 18px;
  padding: 20px;
}

.more-tools-content {
  min-width: 500px !important;
}

.more-tools-content .more-list-item {
  cursor: pointer;
  color: black !important;
}

.more-list-item {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 500;
  color: gray;
}
.accord-user {
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  padding: 6px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 500;
  color: gray;
  border-top: 1px solid white;
}


/* Settings */

.back-banner{
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
  height: 170px;
  width: 100%;
}

.settings-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.settings-wrapper .profile-sec .name {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
}

.settings-wrapper .react-tabs {
  display: flex;
  width: 70%;
  /* height: 400px; */
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: -70px;
}

.settings-wrapper .react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  min-width: 250px !important;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border-right: 1px solid rgb(230, 230, 230);
}

.settings-wrapper .react-tabs__tab {
  list-style: none;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid rgb(230, 230, 230);
}

.settings-wrapper .react-tabs__tab--selected {
  border-left: 4px solid #6eb800;
  outline: none;
}

.settings-wrapper .react-tabs__tab-panel {
  display: none;
  width: 100%;
}

.settings-wrapper .react-tabs__tab-panel--selected {
  display: block;
}

.settings-wrapper .react-tabs__tab {
  padding-left: 24px;
}

.settings-wrapper .react-tabs__tab--selected {
  padding-left: 21px;
}

.settings-wrapper .panel-content {
  width: 100%;
  padding: 20px;
}

.pannel-list{
  color: #4a4a4a;
  font-size: 12px;
  margin-bottom: 10px;
}

.pannel-list span{
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  margin-left: 8px;
}

.dashboard-btn{
  background-color: #ECECEC !important;
  margin-bottom: 20px !important;
  color: black !important;
  text-transform: none !important;
  padding: 8px 25px !important;
  font-size: 15px !important;
}

.dashboard-btn.active{
  background-color: #466FFF !important;
  margin-bottom: 20px !important;
  color: rgb(255, 255, 255) !important;
}

.dashboard-btn svg{
  width: 20px !important;
  margin-right: 10px !important;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
