.wrap {
    position: relative;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    transform-style: preserve-3d;
    perspective: 1000px;
    animation: rotate 14s infinite linear;
  }
  
  @keyframes rotate {
    100% {
      transform: rotateY(360deg) rotateX(360deg);
    }
  }
  .c {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    opacity: 0;
  }
  
  .c:nth-child(1) {
    animation: orbit1 14s infinite;
    animation-delay: 0.01s;
    background-color: #ff0100;
  }
  
  @keyframes orbit1 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-81deg) rotateY(164deg) translateX(200px) rotateZ(81deg);
    }
    80% {
      transform: rotateZ(-81deg) rotateY(164deg) translateX(200px) rotateZ(81deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-81deg) rotateY(164deg) translateX(600px) rotateZ(81deg);
    }
  }
  .c:nth-child(2) {
    animation: orbit2 14s infinite;
    animation-delay: 0.02s;
    background-color: #ff0100;
  }
  
  @keyframes orbit2 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-72deg) rotateY(283deg) translateX(200px) rotateZ(72deg);
    }
    80% {
      transform: rotateZ(-72deg) rotateY(283deg) translateX(200px) rotateZ(72deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-72deg) rotateY(283deg) translateX(600px) rotateZ(72deg);
    }
  }
  .c:nth-child(3) {
    animation: orbit3 14s infinite;
    animation-delay: 0.03s;
    background-color: #ff0200;
  }
  
  @keyframes orbit3 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-189deg) rotateY(128deg) translateX(200px) rotateZ(189deg);
    }
    80% {
      transform: rotateZ(-189deg) rotateY(128deg) translateX(200px) rotateZ(189deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-189deg) rotateY(128deg) translateX(600px) rotateZ(189deg);
    }
  }
  .c:nth-child(4) {
    animation: orbit4 14s infinite;
    animation-delay: 0.04s;
    background-color: #ff0200;
  }
  
  @keyframes orbit4 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-340deg) rotateY(34deg) translateX(200px) rotateZ(340deg);
    }
    80% {
      transform: rotateZ(-340deg) rotateY(34deg) translateX(200px) rotateZ(340deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-340deg) rotateY(34deg) translateX(600px) rotateZ(340deg);
    }
  }
  .c:nth-child(5) {
    animation: orbit5 14s infinite;
    animation-delay: 0.05s;
    background-color: #ff0300;
  }
  
  @keyframes orbit5 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-209deg) rotateY(48deg) translateX(200px) rotateZ(209deg);
    }
    80% {
      transform: rotateZ(-209deg) rotateY(48deg) translateX(200px) rotateZ(209deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-209deg) rotateY(48deg) translateX(600px) rotateZ(209deg);
    }
  }
  .c:nth-child(6) {
    animation: orbit6 14s infinite;
    animation-delay: 0.06s;
    background-color: #ff0300;
  }
  
  @keyframes orbit6 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-228deg) rotateY(311deg) translateX(200px) rotateZ(228deg);
    }
    80% {
      transform: rotateZ(-228deg) rotateY(311deg) translateX(200px) rotateZ(228deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-228deg) rotateY(311deg) translateX(600px) rotateZ(228deg);
    }
  }
  .c:nth-child(7) {
    animation: orbit7 14s infinite;
    animation-delay: 0.07s;
    background-color: #ff0400;
  }
  
  @keyframes orbit7 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-167deg) rotateY(217deg) translateX(200px) rotateZ(167deg);
    }
    80% {
      transform: rotateZ(-167deg) rotateY(217deg) translateX(200px) rotateZ(167deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-167deg) rotateY(217deg) translateX(600px) rotateZ(167deg);
    }
  }
  .c:nth-child(8) {
    animation: orbit8 14s infinite;
    animation-delay: 0.08s;
    background-color: #ff0500;
  }
  
  @keyframes orbit8 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-286deg) rotateY(298deg) translateX(200px) rotateZ(286deg);
    }
    80% {
      transform: rotateZ(-286deg) rotateY(298deg) translateX(200px) rotateZ(286deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-286deg) rotateY(298deg) translateX(600px) rotateZ(286deg);
    }
  }
  .c:nth-child(9) {
    animation: orbit9 14s infinite;
    animation-delay: 0.09s;
    background-color: #ff0500;
  }
  
  @keyframes orbit9 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-193deg) rotateY(12deg) translateX(200px) rotateZ(193deg);
    }
    80% {
      transform: rotateZ(-193deg) rotateY(12deg) translateX(200px) rotateZ(193deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-193deg) rotateY(12deg) translateX(600px) rotateZ(193deg);
    }
  }
  .c:nth-child(10) {
    animation: orbit10 14s infinite;
    animation-delay: 0.1s;
    background-color: #ff0600;
  }
  
  @keyframes orbit10 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-226deg) rotateY(299deg) translateX(200px) rotateZ(226deg);
    }
    80% {
      transform: rotateZ(-226deg) rotateY(299deg) translateX(200px) rotateZ(226deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-226deg) rotateY(299deg) translateX(600px) rotateZ(226deg);
    }
  }
  .c:nth-child(11) {
    animation: orbit11 14s infinite;
    animation-delay: 0.11s;
    background-color: #ff0600;
  }
  
  @keyframes orbit11 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-273deg) rotateY(157deg) translateX(200px) rotateZ(273deg);
    }
    80% {
      transform: rotateZ(-273deg) rotateY(157deg) translateX(200px) rotateZ(273deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-273deg) rotateY(157deg) translateX(600px) rotateZ(273deg);
    }
  }
  .c:nth-child(12) {
    animation: orbit12 14s infinite;
    animation-delay: 0.12s;
    background-color: #ff0700;
  }
  
  @keyframes orbit12 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-190deg) rotateY(237deg) translateX(200px) rotateZ(190deg);
    }
    80% {
      transform: rotateZ(-190deg) rotateY(237deg) translateX(200px) rotateZ(190deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-190deg) rotateY(237deg) translateX(600px) rotateZ(190deg);
    }
  }
  .c:nth-child(13) {
    animation: orbit13 14s infinite;
    animation-delay: 0.13s;
    background-color: #ff0700;
  }
  
  @keyframes orbit13 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-154deg) rotateY(351deg) translateX(200px) rotateZ(154deg);
    }
    80% {
      transform: rotateZ(-154deg) rotateY(351deg) translateX(200px) rotateZ(154deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-154deg) rotateY(351deg) translateX(600px) rotateZ(154deg);
    }
  }
  .c:nth-child(14) {
    animation: orbit14 14s infinite;
    animation-delay: 0.14s;
    background-color: #ff0800;
  }
  
  @keyframes orbit14 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-109deg) rotateY(45deg) translateX(200px) rotateZ(109deg);
    }
    80% {
      transform: rotateZ(-109deg) rotateY(45deg) translateX(200px) rotateZ(109deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-109deg) rotateY(45deg) translateX(600px) rotateZ(109deg);
    }
  }
  .c:nth-child(15) {
    animation: orbit15 14s infinite;
    animation-delay: 0.15s;
    background-color: #ff0900;
  }
  
  @keyframes orbit15 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-82deg) rotateY(290deg) translateX(200px) rotateZ(82deg);
    }
    80% {
      transform: rotateZ(-82deg) rotateY(290deg) translateX(200px) rotateZ(82deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-82deg) rotateY(290deg) translateX(600px) rotateZ(82deg);
    }
  }
  .c:nth-child(16) {
    animation: orbit16 14s infinite;
    animation-delay: 0.16s;
    background-color: #ff0900;
  }
  
  @keyframes orbit16 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-324deg) rotateY(69deg) translateX(200px) rotateZ(324deg);
    }
    80% {
      transform: rotateZ(-324deg) rotateY(69deg) translateX(200px) rotateZ(324deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-324deg) rotateY(69deg) translateX(600px) rotateZ(324deg);
    }
  }
  .c:nth-child(17) {
    animation: orbit17 14s infinite;
    animation-delay: 0.17s;
    background-color: #ff0a00;
  }
  
  @keyframes orbit17 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-131deg) rotateY(124deg) translateX(200px) rotateZ(131deg);
    }
    80% {
      transform: rotateZ(-131deg) rotateY(124deg) translateX(200px) rotateZ(131deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-131deg) rotateY(124deg) translateX(600px) rotateZ(131deg);
    }
  }
  .c:nth-child(18) {
    animation: orbit18 14s infinite;
    animation-delay: 0.18s;
    background-color: #ff0a00;
  }
  
  @keyframes orbit18 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-287deg) rotateY(125deg) translateX(200px) rotateZ(287deg);
    }
    80% {
      transform: rotateZ(-287deg) rotateY(125deg) translateX(200px) rotateZ(287deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-287deg) rotateY(125deg) translateX(600px) rotateZ(287deg);
    }
  }
  .c:nth-child(19) {
    animation: orbit19 14s infinite;
    animation-delay: 0.19s;
    background-color: #ff0b00;
  }
  
  @keyframes orbit19 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-230deg) rotateY(185deg) translateX(200px) rotateZ(230deg);
    }
    80% {
      transform: rotateZ(-230deg) rotateY(185deg) translateX(200px) rotateZ(230deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-230deg) rotateY(185deg) translateX(600px) rotateZ(230deg);
    }
  }
  .c:nth-child(20) {
    animation: orbit20 14s infinite;
    animation-delay: 0.2s;
    background-color: #ff0b00;
  }
  
  @keyframes orbit20 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-50deg) rotateY(132deg) translateX(200px) rotateZ(50deg);
    }
    80% {
      transform: rotateZ(-50deg) rotateY(132deg) translateX(200px) rotateZ(50deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-50deg) rotateY(132deg) translateX(600px) rotateZ(50deg);
    }
  }
  .c:nth-child(21) {
    animation: orbit21 14s infinite;
    animation-delay: 0.21s;
    background-color: #ff0c00;
  }
  
  @keyframes orbit21 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-83deg) rotateY(28deg) translateX(200px) rotateZ(83deg);
    }
    80% {
      transform: rotateZ(-83deg) rotateY(28deg) translateX(200px) rotateZ(83deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-83deg) rotateY(28deg) translateX(600px) rotateZ(83deg);
    }
  }
  .c:nth-child(22) {
    animation: orbit22 14s infinite;
    animation-delay: 0.22s;
    background-color: #ff0c00;
  }
  
  @keyframes orbit22 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-57deg) rotateY(143deg) translateX(200px) rotateZ(57deg);
    }
    80% {
      transform: rotateZ(-57deg) rotateY(143deg) translateX(200px) rotateZ(57deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-57deg) rotateY(143deg) translateX(600px) rotateZ(57deg);
    }
  }
  .c:nth-child(23) {
    animation: orbit23 14s infinite;
    animation-delay: 0.23s;
    background-color: #ff0d00;
  }
  
  @keyframes orbit23 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-288deg) rotateY(190deg) translateX(200px) rotateZ(288deg);
    }
    80% {
      transform: rotateZ(-288deg) rotateY(190deg) translateX(200px) rotateZ(288deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-288deg) rotateY(190deg) translateX(600px) rotateZ(288deg);
    }
  }
  .c:nth-child(24) {
    animation: orbit24 14s infinite;
    animation-delay: 0.24s;
    background-color: #ff0e00;
  }
  
  @keyframes orbit24 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-307deg) rotateY(133deg) translateX(200px) rotateZ(307deg);
    }
    80% {
      transform: rotateZ(-307deg) rotateY(133deg) translateX(200px) rotateZ(307deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-307deg) rotateY(133deg) translateX(600px) rotateZ(307deg);
    }
  }
  .c:nth-child(25) {
    animation: orbit25 14s infinite;
    animation-delay: 0.25s;
    background-color: #ff0e00;
  }
  
  @keyframes orbit25 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-270deg) rotateY(344deg) translateX(200px) rotateZ(270deg);
    }
    80% {
      transform: rotateZ(-270deg) rotateY(344deg) translateX(200px) rotateZ(270deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-270deg) rotateY(344deg) translateX(600px) rotateZ(270deg);
    }
  }
  .c:nth-child(26) {
    animation: orbit26 14s infinite;
    animation-delay: 0.26s;
    background-color: #ff0f00;
  }
  
  @keyframes orbit26 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-353deg) rotateY(348deg) translateX(200px) rotateZ(353deg);
    }
    80% {
      transform: rotateZ(-353deg) rotateY(348deg) translateX(200px) rotateZ(353deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-353deg) rotateY(348deg) translateX(600px) rotateZ(353deg);
    }
  }
  .c:nth-child(27) {
    animation: orbit27 14s infinite;
    animation-delay: 0.27s;
    background-color: #ff0f00;
  }
  
  @keyframes orbit27 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-231deg) rotateY(262deg) translateX(200px) rotateZ(231deg);
    }
    80% {
      transform: rotateZ(-231deg) rotateY(262deg) translateX(200px) rotateZ(231deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-231deg) rotateY(262deg) translateX(600px) rotateZ(231deg);
    }
  }
  .c:nth-child(28) {
    animation: orbit28 14s infinite;
    animation-delay: 0.28s;
    background-color: #ff1000;
  }
  
  @keyframes orbit28 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-339deg) rotateY(340deg) translateX(200px) rotateZ(339deg);
    }
    80% {
      transform: rotateZ(-339deg) rotateY(340deg) translateX(200px) rotateZ(339deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-339deg) rotateY(340deg) translateX(600px) rotateZ(339deg);
    }
  }
  .c:nth-child(29) {
    animation: orbit29 14s infinite;
    animation-delay: 0.29s;
    background-color: #ff1000;
  }
  
  @keyframes orbit29 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-53deg) rotateY(90deg) translateX(200px) rotateZ(53deg);
    }
    80% {
      transform: rotateZ(-53deg) rotateY(90deg) translateX(200px) rotateZ(53deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-53deg) rotateY(90deg) translateX(600px) rotateZ(53deg);
    }
  }
  .c:nth-child(30) {
    animation: orbit30 14s infinite;
    animation-delay: 0.3s;
    background-color: #ff1100;
  }
  
  @keyframes orbit30 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-335deg) rotateY(324deg) translateX(200px) rotateZ(335deg);
    }
    80% {
      transform: rotateZ(-335deg) rotateY(324deg) translateX(200px) rotateZ(335deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-335deg) rotateY(324deg) translateX(600px) rotateZ(335deg);
    }
  }
  .c:nth-child(31) {
    animation: orbit31 14s infinite;
    animation-delay: 0.31s;
    background-color: #ff1200;
  }
  
  @keyframes orbit31 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-217deg) rotateY(61deg) translateX(200px) rotateZ(217deg);
    }
    80% {
      transform: rotateZ(-217deg) rotateY(61deg) translateX(200px) rotateZ(217deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-217deg) rotateY(61deg) translateX(600px) rotateZ(217deg);
    }
  }
  .c:nth-child(32) {
    animation: orbit32 14s infinite;
    animation-delay: 0.32s;
    background-color: #ff1200;
  }
  
  @keyframes orbit32 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-55deg) rotateY(256deg) translateX(200px) rotateZ(55deg);
    }
    80% {
      transform: rotateZ(-55deg) rotateY(256deg) translateX(200px) rotateZ(55deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-55deg) rotateY(256deg) translateX(600px) rotateZ(55deg);
    }
  }
  .c:nth-child(33) {
    animation: orbit33 14s infinite;
    animation-delay: 0.33s;
    background-color: #ff1300;
  }
  
  @keyframes orbit33 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-116deg) rotateY(63deg) translateX(200px) rotateZ(116deg);
    }
    80% {
      transform: rotateZ(-116deg) rotateY(63deg) translateX(200px) rotateZ(116deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-116deg) rotateY(63deg) translateX(600px) rotateZ(116deg);
    }
  }
  .c:nth-child(34) {
    animation: orbit34 14s infinite;
    animation-delay: 0.34s;
    background-color: #ff1300;
  }
  
  @keyframes orbit34 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-344deg) rotateY(41deg) translateX(200px) rotateZ(344deg);
    }
    80% {
      transform: rotateZ(-344deg) rotateY(41deg) translateX(200px) rotateZ(344deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-344deg) rotateY(41deg) translateX(600px) rotateZ(344deg);
    }
  }
  .c:nth-child(35) {
    animation: orbit35 14s infinite;
    animation-delay: 0.35s;
    background-color: #ff1400;
  }
  
  @keyframes orbit35 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-234deg) rotateY(349deg) translateX(200px) rotateZ(234deg);
    }
    80% {
      transform: rotateZ(-234deg) rotateY(349deg) translateX(200px) rotateZ(234deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-234deg) rotateY(349deg) translateX(600px) rotateZ(234deg);
    }
  }
  .c:nth-child(36) {
    animation: orbit36 14s infinite;
    animation-delay: 0.36s;
    background-color: #ff1400;
  }
  
  @keyframes orbit36 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-46deg) rotateY(110deg) translateX(200px) rotateZ(46deg);
    }
    80% {
      transform: rotateZ(-46deg) rotateY(110deg) translateX(200px) rotateZ(46deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-46deg) rotateY(110deg) translateX(600px) rotateZ(46deg);
    }
  }
  .c:nth-child(37) {
    animation: orbit37 14s infinite;
    animation-delay: 0.37s;
    background-color: #ff1500;
  }
  
  @keyframes orbit37 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-292deg) rotateY(160deg) translateX(200px) rotateZ(292deg);
    }
    80% {
      transform: rotateZ(-292deg) rotateY(160deg) translateX(200px) rotateZ(292deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-292deg) rotateY(160deg) translateX(600px) rotateZ(292deg);
    }
  }
  .c:nth-child(38) {
    animation: orbit38 14s infinite;
    animation-delay: 0.38s;
    background-color: #ff1600;
  }
  
  @keyframes orbit38 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-351deg) rotateY(191deg) translateX(200px) rotateZ(351deg);
    }
    80% {
      transform: rotateZ(-351deg) rotateY(191deg) translateX(200px) rotateZ(351deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-351deg) rotateY(191deg) translateX(600px) rotateZ(351deg);
    }
  }
  .c:nth-child(39) {
    animation: orbit39 14s infinite;
    animation-delay: 0.39s;
    background-color: #ff1600;
  }
  
  @keyframes orbit39 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-89deg) rotateY(124deg) translateX(200px) rotateZ(89deg);
    }
    80% {
      transform: rotateZ(-89deg) rotateY(124deg) translateX(200px) rotateZ(89deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-89deg) rotateY(124deg) translateX(600px) rotateZ(89deg);
    }
  }
  .c:nth-child(40) {
    animation: orbit40 14s infinite;
    animation-delay: 0.4s;
    background-color: #ff1700;
  }
  
  @keyframes orbit40 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-122deg) rotateY(217deg) translateX(200px) rotateZ(122deg);
    }
    80% {
      transform: rotateZ(-122deg) rotateY(217deg) translateX(200px) rotateZ(122deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-122deg) rotateY(217deg) translateX(600px) rotateZ(122deg);
    }
  }
  .c:nth-child(41) {
    animation: orbit41 14s infinite;
    animation-delay: 0.41s;
    background-color: #ff1700;
  }
  
  @keyframes orbit41 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-339deg) rotateY(292deg) translateX(200px) rotateZ(339deg);
    }
    80% {
      transform: rotateZ(-339deg) rotateY(292deg) translateX(200px) rotateZ(339deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-339deg) rotateY(292deg) translateX(600px) rotateZ(339deg);
    }
  }
  .c:nth-child(42) {
    animation: orbit42 14s infinite;
    animation-delay: 0.42s;
    background-color: #ff1800;
  }
  
  @keyframes orbit42 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-157deg) rotateY(58deg) translateX(200px) rotateZ(157deg);
    }
    80% {
      transform: rotateZ(-157deg) rotateY(58deg) translateX(200px) rotateZ(157deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-157deg) rotateY(58deg) translateX(600px) rotateZ(157deg);
    }
  }
  .c:nth-child(43) {
    animation: orbit43 14s infinite;
    animation-delay: 0.43s;
    background-color: #ff1800;
  }
  
  @keyframes orbit43 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-26deg) rotateY(321deg) translateX(200px) rotateZ(26deg);
    }
    80% {
      transform: rotateZ(-26deg) rotateY(321deg) translateX(200px) rotateZ(26deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-26deg) rotateY(321deg) translateX(600px) rotateZ(26deg);
    }
  }
  .c:nth-child(44) {
    animation: orbit44 14s infinite;
    animation-delay: 0.44s;
    background-color: #ff1900;
  }
  
  @keyframes orbit44 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-240deg) rotateY(142deg) translateX(200px) rotateZ(240deg);
    }
    80% {
      transform: rotateZ(-240deg) rotateY(142deg) translateX(200px) rotateZ(240deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-240deg) rotateY(142deg) translateX(600px) rotateZ(240deg);
    }
  }
  .c:nth-child(45) {
    animation: orbit45 14s infinite;
    animation-delay: 0.45s;
    background-color: #ff1a00;
  }
  
  @keyframes orbit45 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-101deg) rotateY(254deg) translateX(200px) rotateZ(101deg);
    }
    80% {
      transform: rotateZ(-101deg) rotateY(254deg) translateX(200px) rotateZ(101deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-101deg) rotateY(254deg) translateX(600px) rotateZ(101deg);
    }
  }
  .c:nth-child(46) {
    animation: orbit46 14s infinite;
    animation-delay: 0.46s;
    background-color: #ff1a00;
  }
  
  @keyframes orbit46 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-306deg) rotateY(184deg) translateX(200px) rotateZ(306deg);
    }
    80% {
      transform: rotateZ(-306deg) rotateY(184deg) translateX(200px) rotateZ(306deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-306deg) rotateY(184deg) translateX(600px) rotateZ(306deg);
    }
  }
  .c:nth-child(47) {
    animation: orbit47 14s infinite;
    animation-delay: 0.47s;
    background-color: #ff1b00;
  }
  
  @keyframes orbit47 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-27deg) rotateY(98deg) translateX(200px) rotateZ(27deg);
    }
    80% {
      transform: rotateZ(-27deg) rotateY(98deg) translateX(200px) rotateZ(27deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-27deg) rotateY(98deg) translateX(600px) rotateZ(27deg);
    }
  }
  .c:nth-child(48) {
    animation: orbit48 14s infinite;
    animation-delay: 0.48s;
    background-color: #ff1b00;
  }
  
  @keyframes orbit48 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-62deg) rotateY(335deg) translateX(200px) rotateZ(62deg);
    }
    80% {
      transform: rotateZ(-62deg) rotateY(335deg) translateX(200px) rotateZ(62deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-62deg) rotateY(335deg) translateX(600px) rotateZ(62deg);
    }
  }
  .c:nth-child(49) {
    animation: orbit49 14s infinite;
    animation-delay: 0.49s;
    background-color: #ff1c00;
  }
  
  @keyframes orbit49 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-104deg) rotateY(217deg) translateX(200px) rotateZ(104deg);
    }
    80% {
      transform: rotateZ(-104deg) rotateY(217deg) translateX(200px) rotateZ(104deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-104deg) rotateY(217deg) translateX(600px) rotateZ(104deg);
    }
  }
  .c:nth-child(50) {
    animation: orbit50 14s infinite;
    animation-delay: 0.5s;
    background-color: #ff1c00;
  }
  
  @keyframes orbit50 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-340deg) rotateY(180deg) translateX(200px) rotateZ(340deg);
    }
    80% {
      transform: rotateZ(-340deg) rotateY(180deg) translateX(200px) rotateZ(340deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-340deg) rotateY(180deg) translateX(600px) rotateZ(340deg);
    }
  }
  .c:nth-child(51) {
    animation: orbit51 14s infinite;
    animation-delay: 0.51s;
    background-color: #ff1d00;
  }
  
  @keyframes orbit51 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-30deg) rotateY(273deg) translateX(200px) rotateZ(30deg);
    }
    80% {
      transform: rotateZ(-30deg) rotateY(273deg) translateX(200px) rotateZ(30deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-30deg) rotateY(273deg) translateX(600px) rotateZ(30deg);
    }
  }
  .c:nth-child(52) {
    animation: orbit52 14s infinite;
    animation-delay: 0.52s;
    background-color: #ff1d00;
  }
  
  @keyframes orbit52 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-160deg) rotateY(282deg) translateX(200px) rotateZ(160deg);
    }
    80% {
      transform: rotateZ(-160deg) rotateY(282deg) translateX(200px) rotateZ(160deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-160deg) rotateY(282deg) translateX(600px) rotateZ(160deg);
    }
  }
  .c:nth-child(53) {
    animation: orbit53 14s infinite;
    animation-delay: 0.53s;
    background-color: #ff1e00;
  }
  
  @keyframes orbit53 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-290deg) rotateY(214deg) translateX(200px) rotateZ(290deg);
    }
    80% {
      transform: rotateZ(-290deg) rotateY(214deg) translateX(200px) rotateZ(290deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-290deg) rotateY(214deg) translateX(600px) rotateZ(290deg);
    }
  }
  .c:nth-child(54) {
    animation: orbit54 14s infinite;
    animation-delay: 0.54s;
    background-color: #ff1f00;
  }
  
  @keyframes orbit54 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-149deg) rotateY(256deg) translateX(200px) rotateZ(149deg);
    }
    80% {
      transform: rotateZ(-149deg) rotateY(256deg) translateX(200px) rotateZ(149deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-149deg) rotateY(256deg) translateX(600px) rotateZ(149deg);
    }
  }
  .c:nth-child(55) {
    animation: orbit55 14s infinite;
    animation-delay: 0.55s;
    background-color: #ff1f00;
  }
  
  @keyframes orbit55 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-142deg) rotateY(200deg) translateX(200px) rotateZ(142deg);
    }
    80% {
      transform: rotateZ(-142deg) rotateY(200deg) translateX(200px) rotateZ(142deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-142deg) rotateY(200deg) translateX(600px) rotateZ(142deg);
    }
  }
  .c:nth-child(56) {
    animation: orbit56 14s infinite;
    animation-delay: 0.56s;
    background-color: #ff2000;
  }
  
  @keyframes orbit56 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-340deg) rotateY(45deg) translateX(200px) rotateZ(340deg);
    }
    80% {
      transform: rotateZ(-340deg) rotateY(45deg) translateX(200px) rotateZ(340deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-340deg) rotateY(45deg) translateX(600px) rotateZ(340deg);
    }
  }
  .c:nth-child(57) {
    animation: orbit57 14s infinite;
    animation-delay: 0.57s;
    background-color: #ff2000;
  }
  
  @keyframes orbit57 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-247deg) rotateY(99deg) translateX(200px) rotateZ(247deg);
    }
    80% {
      transform: rotateZ(-247deg) rotateY(99deg) translateX(200px) rotateZ(247deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-247deg) rotateY(99deg) translateX(600px) rotateZ(247deg);
    }
  }
  .c:nth-child(58) {
    animation: orbit58 14s infinite;
    animation-delay: 0.58s;
    background-color: #ff2100;
  }
  
  @keyframes orbit58 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-85deg) rotateY(245deg) translateX(200px) rotateZ(85deg);
    }
    80% {
      transform: rotateZ(-85deg) rotateY(245deg) translateX(200px) rotateZ(85deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-85deg) rotateY(245deg) translateX(600px) rotateZ(85deg);
    }
  }
  .c:nth-child(59) {
    animation: orbit59 14s infinite;
    animation-delay: 0.59s;
    background-color: #ff2100;
  }
  
  @keyframes orbit59 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-268deg) rotateY(102deg) translateX(200px) rotateZ(268deg);
    }
    80% {
      transform: rotateZ(-268deg) rotateY(102deg) translateX(200px) rotateZ(268deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-268deg) rotateY(102deg) translateX(600px) rotateZ(268deg);
    }
  }
  .c:nth-child(60) {
    animation: orbit60 14s infinite;
    animation-delay: 0.6s;
    background-color: #ff2200;
  }
  
  @keyframes orbit60 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-11deg) rotateY(50deg) translateX(200px) rotateZ(11deg);
    }
    80% {
      transform: rotateZ(-11deg) rotateY(50deg) translateX(200px) rotateZ(11deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-11deg) rotateY(50deg) translateX(600px) rotateZ(11deg);
    }
  }
  .c:nth-child(61) {
    animation: orbit61 14s infinite;
    animation-delay: 0.61s;
    background-color: #ff2300;
  }
  
  @keyframes orbit61 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-283deg) rotateY(133deg) translateX(200px) rotateZ(283deg);
    }
    80% {
      transform: rotateZ(-283deg) rotateY(133deg) translateX(200px) rotateZ(283deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-283deg) rotateY(133deg) translateX(600px) rotateZ(283deg);
    }
  }
  .c:nth-child(62) {
    animation: orbit62 14s infinite;
    animation-delay: 0.62s;
    background-color: #ff2300;
  }
  
  @keyframes orbit62 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-302deg) rotateY(302deg) translateX(200px) rotateZ(302deg);
    }
    80% {
      transform: rotateZ(-302deg) rotateY(302deg) translateX(200px) rotateZ(302deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-302deg) rotateY(302deg) translateX(600px) rotateZ(302deg);
    }
  }
  .c:nth-child(63) {
    animation: orbit63 14s infinite;
    animation-delay: 0.63s;
    background-color: #ff2400;
  }
  
  @keyframes orbit63 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-235deg) rotateY(208deg) translateX(200px) rotateZ(235deg);
    }
    80% {
      transform: rotateZ(-235deg) rotateY(208deg) translateX(200px) rotateZ(235deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-235deg) rotateY(208deg) translateX(600px) rotateZ(235deg);
    }
  }
  .c:nth-child(64) {
    animation: orbit64 14s infinite;
    animation-delay: 0.64s;
    background-color: #ff2400;
  }
  
  @keyframes orbit64 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-12deg) rotateY(129deg) translateX(200px) rotateZ(12deg);
    }
    80% {
      transform: rotateZ(-12deg) rotateY(129deg) translateX(200px) rotateZ(12deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-12deg) rotateY(129deg) translateX(600px) rotateZ(12deg);
    }
  }
  .c:nth-child(65) {
    animation: orbit65 14s infinite;
    animation-delay: 0.65s;
    background-color: #ff2500;
  }
  
  @keyframes orbit65 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-230deg) rotateY(124deg) translateX(200px) rotateZ(230deg);
    }
    80% {
      transform: rotateZ(-230deg) rotateY(124deg) translateX(200px) rotateZ(230deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-230deg) rotateY(124deg) translateX(600px) rotateZ(230deg);
    }
  }
  .c:nth-child(66) {
    animation: orbit66 14s infinite;
    animation-delay: 0.66s;
    background-color: #ff2500;
  }
  
  @keyframes orbit66 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-195deg) rotateY(177deg) translateX(200px) rotateZ(195deg);
    }
    80% {
      transform: rotateZ(-195deg) rotateY(177deg) translateX(200px) rotateZ(195deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-195deg) rotateY(177deg) translateX(600px) rotateZ(195deg);
    }
  }
  .c:nth-child(67) {
    animation: orbit67 14s infinite;
    animation-delay: 0.67s;
    background-color: #ff2600;
  }
  
  @keyframes orbit67 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-126deg) rotateY(285deg) translateX(200px) rotateZ(126deg);
    }
    80% {
      transform: rotateZ(-126deg) rotateY(285deg) translateX(200px) rotateZ(126deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-126deg) rotateY(285deg) translateX(600px) rotateZ(126deg);
    }
  }
  .c:nth-child(68) {
    animation: orbit68 14s infinite;
    animation-delay: 0.68s;
    background-color: #ff2700;
  }
  
  @keyframes orbit68 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-151deg) rotateY(41deg) translateX(200px) rotateZ(151deg);
    }
    80% {
      transform: rotateZ(-151deg) rotateY(41deg) translateX(200px) rotateZ(151deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-151deg) rotateY(41deg) translateX(600px) rotateZ(151deg);
    }
  }
  .c:nth-child(69) {
    animation: orbit69 14s infinite;
    animation-delay: 0.69s;
    background-color: #ff2700;
  }
  
  @keyframes orbit69 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-176deg) rotateY(27deg) translateX(200px) rotateZ(176deg);
    }
    80% {
      transform: rotateZ(-176deg) rotateY(27deg) translateX(200px) rotateZ(176deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-176deg) rotateY(27deg) translateX(600px) rotateZ(176deg);
    }
  }
  .c:nth-child(70) {
    animation: orbit70 14s infinite;
    animation-delay: 0.7s;
    background-color: #ff2800;
  }
  
  @keyframes orbit70 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-327deg) rotateY(270deg) translateX(200px) rotateZ(327deg);
    }
    80% {
      transform: rotateZ(-327deg) rotateY(270deg) translateX(200px) rotateZ(327deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-327deg) rotateY(270deg) translateX(600px) rotateZ(327deg);
    }
  }
  .c:nth-child(71) {
    animation: orbit71 14s infinite;
    animation-delay: 0.71s;
    background-color: #ff2800;
  }
  
  @keyframes orbit71 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-234deg) rotateY(330deg) translateX(200px) rotateZ(234deg);
    }
    80% {
      transform: rotateZ(-234deg) rotateY(330deg) translateX(200px) rotateZ(234deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-234deg) rotateY(330deg) translateX(600px) rotateZ(234deg);
    }
  }
  .c:nth-child(72) {
    animation: orbit72 14s infinite;
    animation-delay: 0.72s;
    background-color: #ff2900;
  }
  
  @keyframes orbit72 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-341deg) rotateY(83deg) translateX(200px) rotateZ(341deg);
    }
    80% {
      transform: rotateZ(-341deg) rotateY(83deg) translateX(200px) rotateZ(341deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-341deg) rotateY(83deg) translateX(600px) rotateZ(341deg);
    }
  }
  .c:nth-child(73) {
    animation: orbit73 14s infinite;
    animation-delay: 0.73s;
    background-color: #ff2900;
  }
  
  @keyframes orbit73 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-193deg) rotateY(241deg) translateX(200px) rotateZ(193deg);
    }
    80% {
      transform: rotateZ(-193deg) rotateY(241deg) translateX(200px) rotateZ(193deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-193deg) rotateY(241deg) translateX(600px) rotateZ(193deg);
    }
  }
  .c:nth-child(74) {
    animation: orbit74 14s infinite;
    animation-delay: 0.74s;
    background-color: #ff2a00;
  }
  
  @keyframes orbit74 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-127deg) rotateY(351deg) translateX(200px) rotateZ(127deg);
    }
    80% {
      transform: rotateZ(-127deg) rotateY(351deg) translateX(200px) rotateZ(127deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-127deg) rotateY(351deg) translateX(600px) rotateZ(127deg);
    }
  }
  .c:nth-child(75) {
    animation: orbit75 14s infinite;
    animation-delay: 0.75s;
    background-color: #ff2b00;
  }
  
  @keyframes orbit75 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-358deg) rotateY(299deg) translateX(200px) rotateZ(358deg);
    }
    80% {
      transform: rotateZ(-358deg) rotateY(299deg) translateX(200px) rotateZ(358deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-358deg) rotateY(299deg) translateX(600px) rotateZ(358deg);
    }
  }
  .c:nth-child(76) {
    animation: orbit76 14s infinite;
    animation-delay: 0.76s;
    background-color: #ff2b00;
  }
  
  @keyframes orbit76 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-282deg) rotateY(221deg) translateX(200px) rotateZ(282deg);
    }
    80% {
      transform: rotateZ(-282deg) rotateY(221deg) translateX(200px) rotateZ(282deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-282deg) rotateY(221deg) translateX(600px) rotateZ(282deg);
    }
  }
  .c:nth-child(77) {
    animation: orbit77 14s infinite;
    animation-delay: 0.77s;
    background-color: #ff2c00;
  }
  
  @keyframes orbit77 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-193deg) rotateY(325deg) translateX(200px) rotateZ(193deg);
    }
    80% {
      transform: rotateZ(-193deg) rotateY(325deg) translateX(200px) rotateZ(193deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-193deg) rotateY(325deg) translateX(600px) rotateZ(193deg);
    }
  }
  .c:nth-child(78) {
    animation: orbit78 14s infinite;
    animation-delay: 0.78s;
    background-color: #ff2c00;
  }
  
  @keyframes orbit78 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-96deg) rotateY(75deg) translateX(200px) rotateZ(96deg);
    }
    80% {
      transform: rotateZ(-96deg) rotateY(75deg) translateX(200px) rotateZ(96deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-96deg) rotateY(75deg) translateX(600px) rotateZ(96deg);
    }
  }
  .c:nth-child(79) {
    animation: orbit79 14s infinite;
    animation-delay: 0.79s;
    background-color: #ff2d00;
  }
  
  @keyframes orbit79 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-271deg) rotateY(317deg) translateX(200px) rotateZ(271deg);
    }
    80% {
      transform: rotateZ(-271deg) rotateY(317deg) translateX(200px) rotateZ(271deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-271deg) rotateY(317deg) translateX(600px) rotateZ(271deg);
    }
  }
  .c:nth-child(80) {
    animation: orbit80 14s infinite;
    animation-delay: 0.8s;
    background-color: #ff2d00;
  }
  
  @keyframes orbit80 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-158deg) rotateY(179deg) translateX(200px) rotateZ(158deg);
    }
    80% {
      transform: rotateZ(-158deg) rotateY(179deg) translateX(200px) rotateZ(158deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-158deg) rotateY(179deg) translateX(600px) rotateZ(158deg);
    }
  }
  .c:nth-child(81) {
    animation: orbit81 14s infinite;
    animation-delay: 0.81s;
    background-color: #ff2e00;
  }
  
  @keyframes orbit81 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-193deg) rotateY(334deg) translateX(200px) rotateZ(193deg);
    }
    80% {
      transform: rotateZ(-193deg) rotateY(334deg) translateX(200px) rotateZ(193deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-193deg) rotateY(334deg) translateX(600px) rotateZ(193deg);
    }
  }
  .c:nth-child(82) {
    animation: orbit82 14s infinite;
    animation-delay: 0.82s;
    background-color: #ff2e00;
  }
  
  @keyframes orbit82 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-339deg) rotateY(261deg) translateX(200px) rotateZ(339deg);
    }
    80% {
      transform: rotateZ(-339deg) rotateY(261deg) translateX(200px) rotateZ(339deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-339deg) rotateY(261deg) translateX(600px) rotateZ(339deg);
    }
  }
  .c:nth-child(83) {
    animation: orbit83 14s infinite;
    animation-delay: 0.83s;
    background-color: #ff2f00;
  }
  
  @keyframes orbit83 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-336deg) rotateY(112deg) translateX(200px) rotateZ(336deg);
    }
    80% {
      transform: rotateZ(-336deg) rotateY(112deg) translateX(200px) rotateZ(336deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-336deg) rotateY(112deg) translateX(600px) rotateZ(336deg);
    }
  }
  .c:nth-child(84) {
    animation: orbit84 14s infinite;
    animation-delay: 0.84s;
    background-color: #ff3000;
  }
  
  @keyframes orbit84 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-170deg) rotateY(109deg) translateX(200px) rotateZ(170deg);
    }
    80% {
      transform: rotateZ(-170deg) rotateY(109deg) translateX(200px) rotateZ(170deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-170deg) rotateY(109deg) translateX(600px) rotateZ(170deg);
    }
  }
  .c:nth-child(85) {
    animation: orbit85 14s infinite;
    animation-delay: 0.85s;
    background-color: #ff3000;
  }
  
  @keyframes orbit85 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-256deg) rotateY(288deg) translateX(200px) rotateZ(256deg);
    }
    80% {
      transform: rotateZ(-256deg) rotateY(288deg) translateX(200px) rotateZ(256deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-256deg) rotateY(288deg) translateX(600px) rotateZ(256deg);
    }
  }
  .c:nth-child(86) {
    animation: orbit86 14s infinite;
    animation-delay: 0.86s;
    background-color: #ff3100;
  }
  
  @keyframes orbit86 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-100deg) rotateY(130deg) translateX(200px) rotateZ(100deg);
    }
    80% {
      transform: rotateZ(-100deg) rotateY(130deg) translateX(200px) rotateZ(100deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-100deg) rotateY(130deg) translateX(600px) rotateZ(100deg);
    }
  }
  .c:nth-child(87) {
    animation: orbit87 14s infinite;
    animation-delay: 0.87s;
    background-color: #ff3100;
  }
  
  @keyframes orbit87 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-217deg) rotateY(90deg) translateX(200px) rotateZ(217deg);
    }
    80% {
      transform: rotateZ(-217deg) rotateY(90deg) translateX(200px) rotateZ(217deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-217deg) rotateY(90deg) translateX(600px) rotateZ(217deg);
    }
  }
  .c:nth-child(88) {
    animation: orbit88 14s infinite;
    animation-delay: 0.88s;
    background-color: #ff3200;
  }
  
  @keyframes orbit88 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-95deg) rotateY(6deg) translateX(200px) rotateZ(95deg);
    }
    80% {
      transform: rotateZ(-95deg) rotateY(6deg) translateX(200px) rotateZ(95deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-95deg) rotateY(6deg) translateX(600px) rotateZ(95deg);
    }
  }
  .c:nth-child(89) {
    animation: orbit89 14s infinite;
    animation-delay: 0.89s;
    background-color: #ff3200;
  }
  
  @keyframes orbit89 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-120deg) rotateY(182deg) translateX(200px) rotateZ(120deg);
    }
    80% {
      transform: rotateZ(-120deg) rotateY(182deg) translateX(200px) rotateZ(120deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-120deg) rotateY(182deg) translateX(600px) rotateZ(120deg);
    }
  }
  .c:nth-child(90) {
    animation: orbit90 14s infinite;
    animation-delay: 0.9s;
    background-color: #ff3300;
  }
  
  @keyframes orbit90 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-83deg) rotateY(62deg) translateX(200px) rotateZ(83deg);
    }
    80% {
      transform: rotateZ(-83deg) rotateY(62deg) translateX(200px) rotateZ(83deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-83deg) rotateY(62deg) translateX(600px) rotateZ(83deg);
    }
  }
  .c:nth-child(91) {
    animation: orbit91 14s infinite;
    animation-delay: 0.91s;
    background-color: #ff3400;
  }
  
  @keyframes orbit91 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-142deg) rotateY(182deg) translateX(200px) rotateZ(142deg);
    }
    80% {
      transform: rotateZ(-142deg) rotateY(182deg) translateX(200px) rotateZ(142deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-142deg) rotateY(182deg) translateX(600px) rotateZ(142deg);
    }
  }
  .c:nth-child(92) {
    animation: orbit92 14s infinite;
    animation-delay: 0.92s;
    background-color: #ff3400;
  }
  
  @keyframes orbit92 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-92deg) rotateY(350deg) translateX(200px) rotateZ(92deg);
    }
    80% {
      transform: rotateZ(-92deg) rotateY(350deg) translateX(200px) rotateZ(92deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-92deg) rotateY(350deg) translateX(600px) rotateZ(92deg);
    }
  }
  .c:nth-child(93) {
    animation: orbit93 14s infinite;
    animation-delay: 0.93s;
    background-color: #ff3500;
  }
  
  @keyframes orbit93 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-316deg) rotateY(258deg) translateX(200px) rotateZ(316deg);
    }
    80% {
      transform: rotateZ(-316deg) rotateY(258deg) translateX(200px) rotateZ(316deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-316deg) rotateY(258deg) translateX(600px) rotateZ(316deg);
    }
  }
  .c:nth-child(94) {
    animation: orbit94 14s infinite;
    animation-delay: 0.94s;
    background-color: #ff3500;
  }
  
  @keyframes orbit94 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-77deg) rotateY(322deg) translateX(200px) rotateZ(77deg);
    }
    80% {
      transform: rotateZ(-77deg) rotateY(322deg) translateX(200px) rotateZ(77deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-77deg) rotateY(322deg) translateX(600px) rotateZ(77deg);
    }
  }
  .c:nth-child(95) {
    animation: orbit95 14s infinite;
    animation-delay: 0.95s;
    background-color: #ff3600;
  }
  
  @keyframes orbit95 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-303deg) rotateY(316deg) translateX(200px) rotateZ(303deg);
    }
    80% {
      transform: rotateZ(-303deg) rotateY(316deg) translateX(200px) rotateZ(303deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-303deg) rotateY(316deg) translateX(600px) rotateZ(303deg);
    }
  }
  .c:nth-child(96) {
    animation: orbit96 14s infinite;
    animation-delay: 0.96s;
    background-color: #ff3600;
  }
  
  @keyframes orbit96 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-43deg) rotateY(48deg) translateX(200px) rotateZ(43deg);
    }
    80% {
      transform: rotateZ(-43deg) rotateY(48deg) translateX(200px) rotateZ(43deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-43deg) rotateY(48deg) translateX(600px) rotateZ(43deg);
    }
  }
  .c:nth-child(97) {
    animation: orbit97 14s infinite;
    animation-delay: 0.97s;
    background-color: #ff3700;
  }
  
  @keyframes orbit97 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-203deg) rotateY(283deg) translateX(200px) rotateZ(203deg);
    }
    80% {
      transform: rotateZ(-203deg) rotateY(283deg) translateX(200px) rotateZ(203deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-203deg) rotateY(283deg) translateX(600px) rotateZ(203deg);
    }
  }
  .c:nth-child(98) {
    animation: orbit98 14s infinite;
    animation-delay: 0.98s;
    background-color: #ff3800;
  }
  
  @keyframes orbit98 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-64deg) rotateY(188deg) translateX(200px) rotateZ(64deg);
    }
    80% {
      transform: rotateZ(-64deg) rotateY(188deg) translateX(200px) rotateZ(64deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-64deg) rotateY(188deg) translateX(600px) rotateZ(64deg);
    }
  }
  .c:nth-child(99) {
    animation: orbit99 14s infinite;
    animation-delay: 0.99s;
    background-color: #ff3800;
  }
  
  @keyframes orbit99 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-275deg) rotateY(118deg) translateX(200px) rotateZ(275deg);
    }
    80% {
      transform: rotateZ(-275deg) rotateY(118deg) translateX(200px) rotateZ(275deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-275deg) rotateY(118deg) translateX(600px) rotateZ(275deg);
    }
  }
  .c:nth-child(100) {
    animation: orbit100 14s infinite;
    animation-delay: 1s;
    background-color: #ff3900;
  }
  
  @keyframes orbit100 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-80deg) rotateY(221deg) translateX(200px) rotateZ(80deg);
    }
    80% {
      transform: rotateZ(-80deg) rotateY(221deg) translateX(200px) rotateZ(80deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-80deg) rotateY(221deg) translateX(600px) rotateZ(80deg);
    }
  }
  .c:nth-child(101) {
    animation: orbit101 14s infinite;
    animation-delay: 1.01s;
    background-color: #ff3900;
  }
  
  @keyframes orbit101 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-218deg) rotateY(279deg) translateX(200px) rotateZ(218deg);
    }
    80% {
      transform: rotateZ(-218deg) rotateY(279deg) translateX(200px) rotateZ(218deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-218deg) rotateY(279deg) translateX(600px) rotateZ(218deg);
    }
  }
  .c:nth-child(102) {
    animation: orbit102 14s infinite;
    animation-delay: 1.02s;
    background-color: #ff3a00;
  }
  
  @keyframes orbit102 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-49deg) rotateY(240deg) translateX(200px) rotateZ(49deg);
    }
    80% {
      transform: rotateZ(-49deg) rotateY(240deg) translateX(200px) rotateZ(49deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-49deg) rotateY(240deg) translateX(600px) rotateZ(49deg);
    }
  }
  .c:nth-child(103) {
    animation: orbit103 14s infinite;
    animation-delay: 1.03s;
    background-color: #ff3a00;
  }
  
  @keyframes orbit103 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-16deg) rotateY(327deg) translateX(200px) rotateZ(16deg);
    }
    80% {
      transform: rotateZ(-16deg) rotateY(327deg) translateX(200px) rotateZ(16deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-16deg) rotateY(327deg) translateX(600px) rotateZ(16deg);
    }
  }
  .c:nth-child(104) {
    animation: orbit104 14s infinite;
    animation-delay: 1.04s;
    background-color: #ff3b00;
  }
  
  @keyframes orbit104 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-61deg) rotateY(23deg) translateX(200px) rotateZ(61deg);
    }
    80% {
      transform: rotateZ(-61deg) rotateY(23deg) translateX(200px) rotateZ(61deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-61deg) rotateY(23deg) translateX(600px) rotateZ(61deg);
    }
  }
  .c:nth-child(105) {
    animation: orbit105 14s infinite;
    animation-delay: 1.05s;
    background-color: #ff3c00;
  }
  
  @keyframes orbit105 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-271deg) rotateY(55deg) translateX(200px) rotateZ(271deg);
    }
    80% {
      transform: rotateZ(-271deg) rotateY(55deg) translateX(200px) rotateZ(271deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-271deg) rotateY(55deg) translateX(600px) rotateZ(271deg);
    }
  }
  .c:nth-child(106) {
    animation: orbit106 14s infinite;
    animation-delay: 1.06s;
    background-color: #ff3c00;
  }
  
  @keyframes orbit106 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-325deg) rotateY(281deg) translateX(200px) rotateZ(325deg);
    }
    80% {
      transform: rotateZ(-325deg) rotateY(281deg) translateX(200px) rotateZ(325deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-325deg) rotateY(281deg) translateX(600px) rotateZ(325deg);
    }
  }
  .c:nth-child(107) {
    animation: orbit107 14s infinite;
    animation-delay: 1.07s;
    background-color: #ff3d00;
  }
  
  @keyframes orbit107 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-263deg) rotateY(159deg) translateX(200px) rotateZ(263deg);
    }
    80% {
      transform: rotateZ(-263deg) rotateY(159deg) translateX(200px) rotateZ(263deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-263deg) rotateY(159deg) translateX(600px) rotateZ(263deg);
    }
  }
  .c:nth-child(108) {
    animation: orbit108 14s infinite;
    animation-delay: 1.08s;
    background-color: #ff3d00;
  }
  
  @keyframes orbit108 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-312deg) rotateY(235deg) translateX(200px) rotateZ(312deg);
    }
    80% {
      transform: rotateZ(-312deg) rotateY(235deg) translateX(200px) rotateZ(312deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-312deg) rotateY(235deg) translateX(600px) rotateZ(312deg);
    }
  }
  .c:nth-child(109) {
    animation: orbit109 14s infinite;
    animation-delay: 1.09s;
    background-color: #ff3e00;
  }
  
  @keyframes orbit109 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-201deg) rotateY(36deg) translateX(200px) rotateZ(201deg);
    }
    80% {
      transform: rotateZ(-201deg) rotateY(36deg) translateX(200px) rotateZ(201deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-201deg) rotateY(36deg) translateX(600px) rotateZ(201deg);
    }
  }
  .c:nth-child(110) {
    animation: orbit110 14s infinite;
    animation-delay: 1.1s;
    background-color: #ff3e00;
  }
  
  @keyframes orbit110 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-323deg) rotateY(212deg) translateX(200px) rotateZ(323deg);
    }
    80% {
      transform: rotateZ(-323deg) rotateY(212deg) translateX(200px) rotateZ(323deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-323deg) rotateY(212deg) translateX(600px) rotateZ(323deg);
    }
  }
  .c:nth-child(111) {
    animation: orbit111 14s infinite;
    animation-delay: 1.11s;
    background-color: #ff3f00;
  }
  
  @keyframes orbit111 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-116deg) rotateY(273deg) translateX(200px) rotateZ(116deg);
    }
    80% {
      transform: rotateZ(-116deg) rotateY(273deg) translateX(200px) rotateZ(116deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-116deg) rotateY(273deg) translateX(600px) rotateZ(116deg);
    }
  }
  .c:nth-child(112) {
    animation: orbit112 14s infinite;
    animation-delay: 1.12s;
    background-color: #ff3f00;
  }
  
  @keyframes orbit112 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-315deg) rotateY(8deg) translateX(200px) rotateZ(315deg);
    }
    80% {
      transform: rotateZ(-315deg) rotateY(8deg) translateX(200px) rotateZ(315deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-315deg) rotateY(8deg) translateX(600px) rotateZ(315deg);
    }
  }
  .c:nth-child(113) {
    animation: orbit113 14s infinite;
    animation-delay: 1.13s;
    background-color: #ff4000;
  }
  
  @keyframes orbit113 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-343deg) rotateY(104deg) translateX(200px) rotateZ(343deg);
    }
    80% {
      transform: rotateZ(-343deg) rotateY(104deg) translateX(200px) rotateZ(343deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-343deg) rotateY(104deg) translateX(600px) rotateZ(343deg);
    }
  }
  .c:nth-child(114) {
    animation: orbit114 14s infinite;
    animation-delay: 1.14s;
    background-color: #ff4100;
  }
  
  @keyframes orbit114 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-211deg) rotateY(75deg) translateX(200px) rotateZ(211deg);
    }
    80% {
      transform: rotateZ(-211deg) rotateY(75deg) translateX(200px) rotateZ(211deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-211deg) rotateY(75deg) translateX(600px) rotateZ(211deg);
    }
  }
  .c:nth-child(115) {
    animation: orbit115 14s infinite;
    animation-delay: 1.15s;
    background-color: #ff4100;
  }
  
  @keyframes orbit115 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-84deg) rotateY(177deg) translateX(200px) rotateZ(84deg);
    }
    80% {
      transform: rotateZ(-84deg) rotateY(177deg) translateX(200px) rotateZ(84deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-84deg) rotateY(177deg) translateX(600px) rotateZ(84deg);
    }
  }
  .c:nth-child(116) {
    animation: orbit116 14s infinite;
    animation-delay: 1.16s;
    background-color: #ff4200;
  }
  
  @keyframes orbit116 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-21deg) rotateY(143deg) translateX(200px) rotateZ(21deg);
    }
    80% {
      transform: rotateZ(-21deg) rotateY(143deg) translateX(200px) rotateZ(21deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-21deg) rotateY(143deg) translateX(600px) rotateZ(21deg);
    }
  }
  .c:nth-child(117) {
    animation: orbit117 14s infinite;
    animation-delay: 1.17s;
    background-color: #ff4200;
  }
  
  @keyframes orbit117 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-327deg) rotateY(237deg) translateX(200px) rotateZ(327deg);
    }
    80% {
      transform: rotateZ(-327deg) rotateY(237deg) translateX(200px) rotateZ(327deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-327deg) rotateY(237deg) translateX(600px) rotateZ(327deg);
    }
  }
  .c:nth-child(118) {
    animation: orbit118 14s infinite;
    animation-delay: 1.18s;
    background-color: #ff4300;
  }
  
  @keyframes orbit118 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-118deg) rotateY(322deg) translateX(200px) rotateZ(118deg);
    }
    80% {
      transform: rotateZ(-118deg) rotateY(322deg) translateX(200px) rotateZ(118deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-118deg) rotateY(322deg) translateX(600px) rotateZ(118deg);
    }
  }
  .c:nth-child(119) {
    animation: orbit119 14s infinite;
    animation-delay: 1.19s;
    background-color: #ff4300;
  }
  
  @keyframes orbit119 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-358deg) rotateY(352deg) translateX(200px) rotateZ(358deg);
    }
    80% {
      transform: rotateZ(-358deg) rotateY(352deg) translateX(200px) rotateZ(358deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-358deg) rotateY(352deg) translateX(600px) rotateZ(358deg);
    }
  }
  .c:nth-child(120) {
    animation: orbit120 14s infinite;
    animation-delay: 1.2s;
    background-color: #ff4400;
  }
  
  @keyframes orbit120 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-71deg) rotateY(346deg) translateX(200px) rotateZ(71deg);
    }
    80% {
      transform: rotateZ(-71deg) rotateY(346deg) translateX(200px) rotateZ(71deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-71deg) rotateY(346deg) translateX(600px) rotateZ(71deg);
    }
  }
  .c:nth-child(121) {
    animation: orbit121 14s infinite;
    animation-delay: 1.21s;
    background-color: orangered;
  }
  
  @keyframes orbit121 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-1deg) rotateY(117deg) translateX(200px) rotateZ(1deg);
    }
    80% {
      transform: rotateZ(-1deg) rotateY(117deg) translateX(200px) rotateZ(1deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-1deg) rotateY(117deg) translateX(600px) rotateZ(1deg);
    }
  }
  .c:nth-child(122) {
    animation: orbit122 14s infinite;
    animation-delay: 1.22s;
    background-color: orangered;
  }
  
  @keyframes orbit122 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-141deg) rotateY(330deg) translateX(200px) rotateZ(141deg);
    }
    80% {
      transform: rotateZ(-141deg) rotateY(330deg) translateX(200px) rotateZ(141deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-141deg) rotateY(330deg) translateX(600px) rotateZ(141deg);
    }
  }
  .c:nth-child(123) {
    animation: orbit123 14s infinite;
    animation-delay: 1.23s;
    background-color: #ff4600;
  }
  
  @keyframes orbit123 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-199deg) rotateY(45deg) translateX(200px) rotateZ(199deg);
    }
    80% {
      transform: rotateZ(-199deg) rotateY(45deg) translateX(200px) rotateZ(199deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-199deg) rotateY(45deg) translateX(600px) rotateZ(199deg);
    }
  }
  .c:nth-child(124) {
    animation: orbit124 14s infinite;
    animation-delay: 1.24s;
    background-color: #ff4600;
  }
  
  @keyframes orbit124 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-9deg) rotateY(195deg) translateX(200px) rotateZ(9deg);
    }
    80% {
      transform: rotateZ(-9deg) rotateY(195deg) translateX(200px) rotateZ(9deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-9deg) rotateY(195deg) translateX(600px) rotateZ(9deg);
    }
  }
  .c:nth-child(125) {
    animation: orbit125 14s infinite;
    animation-delay: 1.25s;
    background-color: #ff4700;
  }
  
  @keyframes orbit125 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-133deg) rotateY(215deg) translateX(200px) rotateZ(133deg);
    }
    80% {
      transform: rotateZ(-133deg) rotateY(215deg) translateX(200px) rotateZ(133deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-133deg) rotateY(215deg) translateX(600px) rotateZ(133deg);
    }
  }
  .c:nth-child(126) {
    animation: orbit126 14s infinite;
    animation-delay: 1.26s;
    background-color: #ff4700;
  }
  
  @keyframes orbit126 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-191deg) rotateY(28deg) translateX(200px) rotateZ(191deg);
    }
    80% {
      transform: rotateZ(-191deg) rotateY(28deg) translateX(200px) rotateZ(191deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-191deg) rotateY(28deg) translateX(600px) rotateZ(191deg);
    }
  }
  .c:nth-child(127) {
    animation: orbit127 14s infinite;
    animation-delay: 1.27s;
    background-color: #ff4800;
  }
  
  @keyframes orbit127 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-286deg) rotateY(182deg) translateX(200px) rotateZ(286deg);
    }
    80% {
      transform: rotateZ(-286deg) rotateY(182deg) translateX(200px) rotateZ(286deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-286deg) rotateY(182deg) translateX(600px) rotateZ(286deg);
    }
  }
  .c:nth-child(128) {
    animation: orbit128 14s infinite;
    animation-delay: 1.28s;
    background-color: #ff4900;
  }
  
  @keyframes orbit128 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-200deg) rotateY(204deg) translateX(200px) rotateZ(200deg);
    }
    80% {
      transform: rotateZ(-200deg) rotateY(204deg) translateX(200px) rotateZ(200deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-200deg) rotateY(204deg) translateX(600px) rotateZ(200deg);
    }
  }
  .c:nth-child(129) {
    animation: orbit129 14s infinite;
    animation-delay: 1.29s;
    background-color: #ff4900;
  }
  
  @keyframes orbit129 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-341deg) rotateY(197deg) translateX(200px) rotateZ(341deg);
    }
    80% {
      transform: rotateZ(-341deg) rotateY(197deg) translateX(200px) rotateZ(341deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-341deg) rotateY(197deg) translateX(600px) rotateZ(341deg);
    }
  }
  .c:nth-child(130) {
    animation: orbit130 14s infinite;
    animation-delay: 1.3s;
    background-color: #ff4a00;
  }
  
  @keyframes orbit130 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-296deg) rotateY(349deg) translateX(200px) rotateZ(296deg);
    }
    80% {
      transform: rotateZ(-296deg) rotateY(349deg) translateX(200px) rotateZ(296deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-296deg) rotateY(349deg) translateX(600px) rotateZ(296deg);
    }
  }
  .c:nth-child(131) {
    animation: orbit131 14s infinite;
    animation-delay: 1.31s;
    background-color: #ff4a00;
  }
  
  @keyframes orbit131 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-128deg) rotateY(38deg) translateX(200px) rotateZ(128deg);
    }
    80% {
      transform: rotateZ(-128deg) rotateY(38deg) translateX(200px) rotateZ(128deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-128deg) rotateY(38deg) translateX(600px) rotateZ(128deg);
    }
  }
  .c:nth-child(132) {
    animation: orbit132 14s infinite;
    animation-delay: 1.32s;
    background-color: #ff4b00;
  }
  
  @keyframes orbit132 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-262deg) rotateY(271deg) translateX(200px) rotateZ(262deg);
    }
    80% {
      transform: rotateZ(-262deg) rotateY(271deg) translateX(200px) rotateZ(262deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-262deg) rotateY(271deg) translateX(600px) rotateZ(262deg);
    }
  }
  .c:nth-child(133) {
    animation: orbit133 14s infinite;
    animation-delay: 1.33s;
    background-color: #ff4b00;
  }
  
  @keyframes orbit133 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-115deg) rotateY(320deg) translateX(200px) rotateZ(115deg);
    }
    80% {
      transform: rotateZ(-115deg) rotateY(320deg) translateX(200px) rotateZ(115deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-115deg) rotateY(320deg) translateX(600px) rotateZ(115deg);
    }
  }
  .c:nth-child(134) {
    animation: orbit134 14s infinite;
    animation-delay: 1.34s;
    background-color: #ff4c00;
  }
  
  @keyframes orbit134 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-146deg) rotateY(225deg) translateX(200px) rotateZ(146deg);
    }
    80% {
      transform: rotateZ(-146deg) rotateY(225deg) translateX(200px) rotateZ(146deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-146deg) rotateY(225deg) translateX(600px) rotateZ(146deg);
    }
  }
  .c:nth-child(135) {
    animation: orbit135 14s infinite;
    animation-delay: 1.35s;
    background-color: #ff4d00;
  }
  
  @keyframes orbit135 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-43deg) rotateY(70deg) translateX(200px) rotateZ(43deg);
    }
    80% {
      transform: rotateZ(-43deg) rotateY(70deg) translateX(200px) rotateZ(43deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-43deg) rotateY(70deg) translateX(600px) rotateZ(43deg);
    }
  }
  .c:nth-child(136) {
    animation: orbit136 14s infinite;
    animation-delay: 1.36s;
    background-color: #ff4d00;
  }
  
  @keyframes orbit136 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-255deg) rotateY(318deg) translateX(200px) rotateZ(255deg);
    }
    80% {
      transform: rotateZ(-255deg) rotateY(318deg) translateX(200px) rotateZ(255deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-255deg) rotateY(318deg) translateX(600px) rotateZ(255deg);
    }
  }
  .c:nth-child(137) {
    animation: orbit137 14s infinite;
    animation-delay: 1.37s;
    background-color: #ff4e00;
  }
  
  @keyframes orbit137 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-139deg) rotateY(172deg) translateX(200px) rotateZ(139deg);
    }
    80% {
      transform: rotateZ(-139deg) rotateY(172deg) translateX(200px) rotateZ(139deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-139deg) rotateY(172deg) translateX(600px) rotateZ(139deg);
    }
  }
  .c:nth-child(138) {
    animation: orbit138 14s infinite;
    animation-delay: 1.38s;
    background-color: #ff4e00;
  }
  
  @keyframes orbit138 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-61deg) rotateY(11deg) translateX(200px) rotateZ(61deg);
    }
    80% {
      transform: rotateZ(-61deg) rotateY(11deg) translateX(200px) rotateZ(61deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-61deg) rotateY(11deg) translateX(600px) rotateZ(61deg);
    }
  }
  .c:nth-child(139) {
    animation: orbit139 14s infinite;
    animation-delay: 1.39s;
    background-color: #ff4f00;
  }
  
  @keyframes orbit139 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-87deg) rotateY(28deg) translateX(200px) rotateZ(87deg);
    }
    80% {
      transform: rotateZ(-87deg) rotateY(28deg) translateX(200px) rotateZ(87deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-87deg) rotateY(28deg) translateX(600px) rotateZ(87deg);
    }
  }
  .c:nth-child(140) {
    animation: orbit140 14s infinite;
    animation-delay: 1.4s;
    background-color: #ff4f00;
  }
  
  @keyframes orbit140 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-164deg) rotateY(290deg) translateX(200px) rotateZ(164deg);
    }
    80% {
      transform: rotateZ(-164deg) rotateY(290deg) translateX(200px) rotateZ(164deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-164deg) rotateY(290deg) translateX(600px) rotateZ(164deg);
    }
  }
  .c:nth-child(141) {
    animation: orbit141 14s infinite;
    animation-delay: 1.41s;
    background-color: #ff5000;
  }
  
  @keyframes orbit141 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-262deg) rotateY(115deg) translateX(200px) rotateZ(262deg);
    }
    80% {
      transform: rotateZ(-262deg) rotateY(115deg) translateX(200px) rotateZ(262deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-262deg) rotateY(115deg) translateX(600px) rotateZ(262deg);
    }
  }
  .c:nth-child(142) {
    animation: orbit142 14s infinite;
    animation-delay: 1.42s;
    background-color: #ff5000;
  }
  
  @keyframes orbit142 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-112deg) rotateY(267deg) translateX(200px) rotateZ(112deg);
    }
    80% {
      transform: rotateZ(-112deg) rotateY(267deg) translateX(200px) rotateZ(112deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-112deg) rotateY(267deg) translateX(600px) rotateZ(112deg);
    }
  }
  .c:nth-child(143) {
    animation: orbit143 14s infinite;
    animation-delay: 1.43s;
    background-color: #ff5100;
  }
  
  @keyframes orbit143 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-328deg) rotateY(284deg) translateX(200px) rotateZ(328deg);
    }
    80% {
      transform: rotateZ(-328deg) rotateY(284deg) translateX(200px) rotateZ(328deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-328deg) rotateY(284deg) translateX(600px) rotateZ(328deg);
    }
  }
  .c:nth-child(144) {
    animation: orbit144 14s infinite;
    animation-delay: 1.44s;
    background-color: #ff5200;
  }
  
  @keyframes orbit144 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-91deg) rotateY(335deg) translateX(200px) rotateZ(91deg);
    }
    80% {
      transform: rotateZ(-91deg) rotateY(335deg) translateX(200px) rotateZ(91deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-91deg) rotateY(335deg) translateX(600px) rotateZ(91deg);
    }
  }
  .c:nth-child(145) {
    animation: orbit145 14s infinite;
    animation-delay: 1.45s;
    background-color: #ff5200;
  }
  
  @keyframes orbit145 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-254deg) rotateY(41deg) translateX(200px) rotateZ(254deg);
    }
    80% {
      transform: rotateZ(-254deg) rotateY(41deg) translateX(200px) rotateZ(254deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-254deg) rotateY(41deg) translateX(600px) rotateZ(254deg);
    }
  }
  .c:nth-child(146) {
    animation: orbit146 14s infinite;
    animation-delay: 1.46s;
    background-color: #ff5300;
  }
  
  @keyframes orbit146 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-223deg) rotateY(151deg) translateX(200px) rotateZ(223deg);
    }
    80% {
      transform: rotateZ(-223deg) rotateY(151deg) translateX(200px) rotateZ(223deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-223deg) rotateY(151deg) translateX(600px) rotateZ(223deg);
    }
  }
  .c:nth-child(147) {
    animation: orbit147 14s infinite;
    animation-delay: 1.47s;
    background-color: #ff5300;
  }
  
  @keyframes orbit147 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-255deg) rotateY(120deg) translateX(200px) rotateZ(255deg);
    }
    80% {
      transform: rotateZ(-255deg) rotateY(120deg) translateX(200px) rotateZ(255deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-255deg) rotateY(120deg) translateX(600px) rotateZ(255deg);
    }
  }
  .c:nth-child(148) {
    animation: orbit148 14s infinite;
    animation-delay: 1.48s;
    background-color: #ff5400;
  }
  
  @keyframes orbit148 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-63deg) rotateY(259deg) translateX(200px) rotateZ(63deg);
    }
    80% {
      transform: rotateZ(-63deg) rotateY(259deg) translateX(200px) rotateZ(63deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-63deg) rotateY(259deg) translateX(600px) rotateZ(63deg);
    }
  }
  .c:nth-child(149) {
    animation: orbit149 14s infinite;
    animation-delay: 1.49s;
    background-color: #ff5400;
  }
  
  @keyframes orbit149 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-339deg) rotateY(232deg) translateX(200px) rotateZ(339deg);
    }
    80% {
      transform: rotateZ(-339deg) rotateY(232deg) translateX(200px) rotateZ(339deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-339deg) rotateY(232deg) translateX(600px) rotateZ(339deg);
    }
  }
  .c:nth-child(150) {
    animation: orbit150 14s infinite;
    animation-delay: 1.5s;
    background-color: #ff5500;
  }
  
  @keyframes orbit150 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-266deg) rotateY(192deg) translateX(200px) rotateZ(266deg);
    }
    80% {
      transform: rotateZ(-266deg) rotateY(192deg) translateX(200px) rotateZ(266deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-266deg) rotateY(192deg) translateX(600px) rotateZ(266deg);
    }
  }
  .c:nth-child(151) {
    animation: orbit151 14s infinite;
    animation-delay: 1.51s;
    background-color: #ff5600;
  }
  
  @keyframes orbit151 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-259deg) rotateY(70deg) translateX(200px) rotateZ(259deg);
    }
    80% {
      transform: rotateZ(-259deg) rotateY(70deg) translateX(200px) rotateZ(259deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-259deg) rotateY(70deg) translateX(600px) rotateZ(259deg);
    }
  }
  .c:nth-child(152) {
    animation: orbit152 14s infinite;
    animation-delay: 1.52s;
    background-color: #ff5600;
  }
  
  @keyframes orbit152 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-352deg) rotateY(310deg) translateX(200px) rotateZ(352deg);
    }
    80% {
      transform: rotateZ(-352deg) rotateY(310deg) translateX(200px) rotateZ(352deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-352deg) rotateY(310deg) translateX(600px) rotateZ(352deg);
    }
  }
  .c:nth-child(153) {
    animation: orbit153 14s infinite;
    animation-delay: 1.53s;
    background-color: #ff5700;
  }
  
  @keyframes orbit153 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-53deg) rotateY(43deg) translateX(200px) rotateZ(53deg);
    }
    80% {
      transform: rotateZ(-53deg) rotateY(43deg) translateX(200px) rotateZ(53deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-53deg) rotateY(43deg) translateX(600px) rotateZ(53deg);
    }
  }
  .c:nth-child(154) {
    animation: orbit154 14s infinite;
    animation-delay: 1.54s;
    background-color: #ff5700;
  }
  
  @keyframes orbit154 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-307deg) rotateY(154deg) translateX(200px) rotateZ(307deg);
    }
    80% {
      transform: rotateZ(-307deg) rotateY(154deg) translateX(200px) rotateZ(307deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-307deg) rotateY(154deg) translateX(600px) rotateZ(307deg);
    }
  }
  .c:nth-child(155) {
    animation: orbit155 14s infinite;
    animation-delay: 1.55s;
    background-color: #ff5800;
  }
  
  @keyframes orbit155 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-199deg) rotateY(160deg) translateX(200px) rotateZ(199deg);
    }
    80% {
      transform: rotateZ(-199deg) rotateY(160deg) translateX(200px) rotateZ(199deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-199deg) rotateY(160deg) translateX(600px) rotateZ(199deg);
    }
  }
  .c:nth-child(156) {
    animation: orbit156 14s infinite;
    animation-delay: 1.56s;
    background-color: #ff5800;
  }
  
  @keyframes orbit156 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-263deg) rotateY(319deg) translateX(200px) rotateZ(263deg);
    }
    80% {
      transform: rotateZ(-263deg) rotateY(319deg) translateX(200px) rotateZ(263deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-263deg) rotateY(319deg) translateX(600px) rotateZ(263deg);
    }
  }
  .c:nth-child(157) {
    animation: orbit157 14s infinite;
    animation-delay: 1.57s;
    background-color: #ff5900;
  }
  
  @keyframes orbit157 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-86deg) rotateY(295deg) translateX(200px) rotateZ(86deg);
    }
    80% {
      transform: rotateZ(-86deg) rotateY(295deg) translateX(200px) rotateZ(86deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-86deg) rotateY(295deg) translateX(600px) rotateZ(86deg);
    }
  }
  .c:nth-child(158) {
    animation: orbit158 14s infinite;
    animation-delay: 1.58s;
    background-color: #ff5a00;
  }
  
  @keyframes orbit158 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-10deg) rotateY(323deg) translateX(200px) rotateZ(10deg);
    }
    80% {
      transform: rotateZ(-10deg) rotateY(323deg) translateX(200px) rotateZ(10deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-10deg) rotateY(323deg) translateX(600px) rotateZ(10deg);
    }
  }
  .c:nth-child(159) {
    animation: orbit159 14s infinite;
    animation-delay: 1.59s;
    background-color: #ff5a00;
  }
  
  @keyframes orbit159 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-132deg) rotateY(349deg) translateX(200px) rotateZ(132deg);
    }
    80% {
      transform: rotateZ(-132deg) rotateY(349deg) translateX(200px) rotateZ(132deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-132deg) rotateY(349deg) translateX(600px) rotateZ(132deg);
    }
  }
  .c:nth-child(160) {
    animation: orbit160 14s infinite;
    animation-delay: 1.6s;
    background-color: #ff5b00;
  }
  
  @keyframes orbit160 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-171deg) rotateY(356deg) translateX(200px) rotateZ(171deg);
    }
    80% {
      transform: rotateZ(-171deg) rotateY(356deg) translateX(200px) rotateZ(171deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-171deg) rotateY(356deg) translateX(600px) rotateZ(171deg);
    }
  }
  .c:nth-child(161) {
    animation: orbit161 14s infinite;
    animation-delay: 1.61s;
    background-color: #ff5b00;
  }
  
  @keyframes orbit161 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-252deg) rotateY(87deg) translateX(200px) rotateZ(252deg);
    }
    80% {
      transform: rotateZ(-252deg) rotateY(87deg) translateX(200px) rotateZ(252deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-252deg) rotateY(87deg) translateX(600px) rotateZ(252deg);
    }
  }
  .c:nth-child(162) {
    animation: orbit162 14s infinite;
    animation-delay: 1.62s;
    background-color: #ff5c00;
  }
  
  @keyframes orbit162 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-173deg) rotateY(85deg) translateX(200px) rotateZ(173deg);
    }
    80% {
      transform: rotateZ(-173deg) rotateY(85deg) translateX(200px) rotateZ(173deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-173deg) rotateY(85deg) translateX(600px) rotateZ(173deg);
    }
  }
  .c:nth-child(163) {
    animation: orbit163 14s infinite;
    animation-delay: 1.63s;
    background-color: #ff5c00;
  }
  
  @keyframes orbit163 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-124deg) rotateY(351deg) translateX(200px) rotateZ(124deg);
    }
    80% {
      transform: rotateZ(-124deg) rotateY(351deg) translateX(200px) rotateZ(124deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-124deg) rotateY(351deg) translateX(600px) rotateZ(124deg);
    }
  }
  .c:nth-child(164) {
    animation: orbit164 14s infinite;
    animation-delay: 1.64s;
    background-color: #ff5d00;
  }
  
  @keyframes orbit164 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-147deg) rotateY(42deg) translateX(200px) rotateZ(147deg);
    }
    80% {
      transform: rotateZ(-147deg) rotateY(42deg) translateX(200px) rotateZ(147deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-147deg) rotateY(42deg) translateX(600px) rotateZ(147deg);
    }
  }
  .c:nth-child(165) {
    animation: orbit165 14s infinite;
    animation-delay: 1.65s;
    background-color: #ff5e00;
  }
  
  @keyframes orbit165 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-47deg) rotateY(16deg) translateX(200px) rotateZ(47deg);
    }
    80% {
      transform: rotateZ(-47deg) rotateY(16deg) translateX(200px) rotateZ(47deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-47deg) rotateY(16deg) translateX(600px) rotateZ(47deg);
    }
  }
  .c:nth-child(166) {
    animation: orbit166 14s infinite;
    animation-delay: 1.66s;
    background-color: #ff5e00;
  }
  
  @keyframes orbit166 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-99deg) rotateY(269deg) translateX(200px) rotateZ(99deg);
    }
    80% {
      transform: rotateZ(-99deg) rotateY(269deg) translateX(200px) rotateZ(99deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-99deg) rotateY(269deg) translateX(600px) rotateZ(99deg);
    }
  }
  .c:nth-child(167) {
    animation: orbit167 14s infinite;
    animation-delay: 1.67s;
    background-color: #ff5f00;
  }
  
  @keyframes orbit167 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-323deg) rotateY(247deg) translateX(200px) rotateZ(323deg);
    }
    80% {
      transform: rotateZ(-323deg) rotateY(247deg) translateX(200px) rotateZ(323deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-323deg) rotateY(247deg) translateX(600px) rotateZ(323deg);
    }
  }
  .c:nth-child(168) {
    animation: orbit168 14s infinite;
    animation-delay: 1.68s;
    background-color: #ff5f00;
  }
  
  @keyframes orbit168 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-253deg) rotateY(66deg) translateX(200px) rotateZ(253deg);
    }
    80% {
      transform: rotateZ(-253deg) rotateY(66deg) translateX(200px) rotateZ(253deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-253deg) rotateY(66deg) translateX(600px) rotateZ(253deg);
    }
  }
  .c:nth-child(169) {
    animation: orbit169 14s infinite;
    animation-delay: 1.69s;
    background-color: #ff6000;
  }
  
  @keyframes orbit169 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-235deg) rotateY(45deg) translateX(200px) rotateZ(235deg);
    }
    80% {
      transform: rotateZ(-235deg) rotateY(45deg) translateX(200px) rotateZ(235deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-235deg) rotateY(45deg) translateX(600px) rotateZ(235deg);
    }
  }
  .c:nth-child(170) {
    animation: orbit170 14s infinite;
    animation-delay: 1.7s;
    background-color: #ff6000;
  }
  
  @keyframes orbit170 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-196deg) rotateY(316deg) translateX(200px) rotateZ(196deg);
    }
    80% {
      transform: rotateZ(-196deg) rotateY(316deg) translateX(200px) rotateZ(196deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-196deg) rotateY(316deg) translateX(600px) rotateZ(196deg);
    }
  }
  .c:nth-child(171) {
    animation: orbit171 14s infinite;
    animation-delay: 1.71s;
    background-color: #ff6100;
  }
  
  @keyframes orbit171 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-38deg) rotateY(101deg) translateX(200px) rotateZ(38deg);
    }
    80% {
      transform: rotateZ(-38deg) rotateY(101deg) translateX(200px) rotateZ(38deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-38deg) rotateY(101deg) translateX(600px) rotateZ(38deg);
    }
  }
  .c:nth-child(172) {
    animation: orbit172 14s infinite;
    animation-delay: 1.72s;
    background-color: #ff6100;
  }
  
  @keyframes orbit172 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-95deg) rotateY(284deg) translateX(200px) rotateZ(95deg);
    }
    80% {
      transform: rotateZ(-95deg) rotateY(284deg) translateX(200px) rotateZ(95deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-95deg) rotateY(284deg) translateX(600px) rotateZ(95deg);
    }
  }
  .c:nth-child(173) {
    animation: orbit173 14s infinite;
    animation-delay: 1.73s;
    background-color: #ff6200;
  }
  
  @keyframes orbit173 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-99deg) rotateY(296deg) translateX(200px) rotateZ(99deg);
    }
    80% {
      transform: rotateZ(-99deg) rotateY(296deg) translateX(200px) rotateZ(99deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-99deg) rotateY(296deg) translateX(600px) rotateZ(99deg);
    }
  }
  .c:nth-child(174) {
    animation: orbit174 14s infinite;
    animation-delay: 1.74s;
    background-color: #ff6300;
  }
  
  @keyframes orbit174 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-329deg) rotateY(151deg) translateX(200px) rotateZ(329deg);
    }
    80% {
      transform: rotateZ(-329deg) rotateY(151deg) translateX(200px) rotateZ(329deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-329deg) rotateY(151deg) translateX(600px) rotateZ(329deg);
    }
  }
  .c:nth-child(175) {
    animation: orbit175 14s infinite;
    animation-delay: 1.75s;
    background-color: #ff6300;
  }
  
  @keyframes orbit175 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-278deg) rotateY(41deg) translateX(200px) rotateZ(278deg);
    }
    80% {
      transform: rotateZ(-278deg) rotateY(41deg) translateX(200px) rotateZ(278deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-278deg) rotateY(41deg) translateX(600px) rotateZ(278deg);
    }
  }
  .c:nth-child(176) {
    animation: orbit176 14s infinite;
    animation-delay: 1.76s;
    background-color: #ff6400;
  }
  
  @keyframes orbit176 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-154deg) rotateY(241deg) translateX(200px) rotateZ(154deg);
    }
    80% {
      transform: rotateZ(-154deg) rotateY(241deg) translateX(200px) rotateZ(154deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-154deg) rotateY(241deg) translateX(600px) rotateZ(154deg);
    }
  }
  .c:nth-child(177) {
    animation: orbit177 14s infinite;
    animation-delay: 1.77s;
    background-color: #ff6400;
  }
  
  @keyframes orbit177 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-142deg) rotateY(275deg) translateX(200px) rotateZ(142deg);
    }
    80% {
      transform: rotateZ(-142deg) rotateY(275deg) translateX(200px) rotateZ(142deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-142deg) rotateY(275deg) translateX(600px) rotateZ(142deg);
    }
  }
  .c:nth-child(178) {
    animation: orbit178 14s infinite;
    animation-delay: 1.78s;
    background-color: #ff6500;
  }
  
  @keyframes orbit178 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-284deg) rotateY(207deg) translateX(200px) rotateZ(284deg);
    }
    80% {
      transform: rotateZ(-284deg) rotateY(207deg) translateX(200px) rotateZ(284deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-284deg) rotateY(207deg) translateX(600px) rotateZ(284deg);
    }
  }
  .c:nth-child(179) {
    animation: orbit179 14s infinite;
    animation-delay: 1.79s;
    background-color: #ff6500;
  }
  
  @keyframes orbit179 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-116deg) rotateY(324deg) translateX(200px) rotateZ(116deg);
    }
    80% {
      transform: rotateZ(-116deg) rotateY(324deg) translateX(200px) rotateZ(116deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-116deg) rotateY(324deg) translateX(600px) rotateZ(116deg);
    }
  }
  .c:nth-child(180) {
    animation: orbit180 14s infinite;
    animation-delay: 1.8s;
    background-color: #ff6600;
  }
  
  @keyframes orbit180 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-79deg) rotateY(71deg) translateX(200px) rotateZ(79deg);
    }
    80% {
      transform: rotateZ(-79deg) rotateY(71deg) translateX(200px) rotateZ(79deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-79deg) rotateY(71deg) translateX(600px) rotateZ(79deg);
    }
  }
  .c:nth-child(181) {
    animation: orbit181 14s infinite;
    animation-delay: 1.81s;
    background-color: #ff6700;
  }
  
  @keyframes orbit181 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-195deg) rotateY(138deg) translateX(200px) rotateZ(195deg);
    }
    80% {
      transform: rotateZ(-195deg) rotateY(138deg) translateX(200px) rotateZ(195deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-195deg) rotateY(138deg) translateX(600px) rotateZ(195deg);
    }
  }
  .c:nth-child(182) {
    animation: orbit182 14s infinite;
    animation-delay: 1.82s;
    background-color: #ff6700;
  }
  
  @keyframes orbit182 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-319deg) rotateY(219deg) translateX(200px) rotateZ(319deg);
    }
    80% {
      transform: rotateZ(-319deg) rotateY(219deg) translateX(200px) rotateZ(319deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-319deg) rotateY(219deg) translateX(600px) rotateZ(319deg);
    }
  }
  .c:nth-child(183) {
    animation: orbit183 14s infinite;
    animation-delay: 1.83s;
    background-color: #ff6800;
  }
  
  @keyframes orbit183 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-353deg) rotateY(330deg) translateX(200px) rotateZ(353deg);
    }
    80% {
      transform: rotateZ(-353deg) rotateY(330deg) translateX(200px) rotateZ(353deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-353deg) rotateY(330deg) translateX(600px) rotateZ(353deg);
    }
  }
  .c:nth-child(184) {
    animation: orbit184 14s infinite;
    animation-delay: 1.84s;
    background-color: #ff6800;
  }
  
  @keyframes orbit184 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-79deg) rotateY(325deg) translateX(200px) rotateZ(79deg);
    }
    80% {
      transform: rotateZ(-79deg) rotateY(325deg) translateX(200px) rotateZ(79deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-79deg) rotateY(325deg) translateX(600px) rotateZ(79deg);
    }
  }
  .c:nth-child(185) {
    animation: orbit185 14s infinite;
    animation-delay: 1.85s;
    background-color: #ff6900;
  }
  
  @keyframes orbit185 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-163deg) rotateY(208deg) translateX(200px) rotateZ(163deg);
    }
    80% {
      transform: rotateZ(-163deg) rotateY(208deg) translateX(200px) rotateZ(163deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-163deg) rotateY(208deg) translateX(600px) rotateZ(163deg);
    }
  }
  .c:nth-child(186) {
    animation: orbit186 14s infinite;
    animation-delay: 1.86s;
    background-color: #ff6900;
  }
  
  @keyframes orbit186 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-21deg) rotateY(151deg) translateX(200px) rotateZ(21deg);
    }
    80% {
      transform: rotateZ(-21deg) rotateY(151deg) translateX(200px) rotateZ(21deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-21deg) rotateY(151deg) translateX(600px) rotateZ(21deg);
    }
  }
  .c:nth-child(187) {
    animation: orbit187 14s infinite;
    animation-delay: 1.87s;
    background-color: #ff6a00;
  }
  
  @keyframes orbit187 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-8deg) rotateY(89deg) translateX(200px) rotateZ(8deg);
    }
    80% {
      transform: rotateZ(-8deg) rotateY(89deg) translateX(200px) rotateZ(8deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-8deg) rotateY(89deg) translateX(600px) rotateZ(8deg);
    }
  }
  .c:nth-child(188) {
    animation: orbit188 14s infinite;
    animation-delay: 1.88s;
    background-color: #ff6b00;
  }
  
  @keyframes orbit188 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-305deg) rotateY(182deg) translateX(200px) rotateZ(305deg);
    }
    80% {
      transform: rotateZ(-305deg) rotateY(182deg) translateX(200px) rotateZ(305deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-305deg) rotateY(182deg) translateX(600px) rotateZ(305deg);
    }
  }
  .c:nth-child(189) {
    animation: orbit189 14s infinite;
    animation-delay: 1.89s;
    background-color: #ff6b00;
  }
  
  @keyframes orbit189 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-316deg) rotateY(288deg) translateX(200px) rotateZ(316deg);
    }
    80% {
      transform: rotateZ(-316deg) rotateY(288deg) translateX(200px) rotateZ(316deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-316deg) rotateY(288deg) translateX(600px) rotateZ(316deg);
    }
  }
  .c:nth-child(190) {
    animation: orbit190 14s infinite;
    animation-delay: 1.9s;
    background-color: #ff6c00;
  }
  
  @keyframes orbit190 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-339deg) rotateY(356deg) translateX(200px) rotateZ(339deg);
    }
    80% {
      transform: rotateZ(-339deg) rotateY(356deg) translateX(200px) rotateZ(339deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-339deg) rotateY(356deg) translateX(600px) rotateZ(339deg);
    }
  }
  .c:nth-child(191) {
    animation: orbit191 14s infinite;
    animation-delay: 1.91s;
    background-color: #ff6c00;
  }
  
  @keyframes orbit191 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-36deg) rotateY(146deg) translateX(200px) rotateZ(36deg);
    }
    80% {
      transform: rotateZ(-36deg) rotateY(146deg) translateX(200px) rotateZ(36deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-36deg) rotateY(146deg) translateX(600px) rotateZ(36deg);
    }
  }
  .c:nth-child(192) {
    animation: orbit192 14s infinite;
    animation-delay: 1.92s;
    background-color: #ff6d00;
  }
  
  @keyframes orbit192 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-173deg) rotateY(139deg) translateX(200px) rotateZ(173deg);
    }
    80% {
      transform: rotateZ(-173deg) rotateY(139deg) translateX(200px) rotateZ(173deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-173deg) rotateY(139deg) translateX(600px) rotateZ(173deg);
    }
  }
  .c:nth-child(193) {
    animation: orbit193 14s infinite;
    animation-delay: 1.93s;
    background-color: #ff6d00;
  }
  
  @keyframes orbit193 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-164deg) rotateY(39deg) translateX(200px) rotateZ(164deg);
    }
    80% {
      transform: rotateZ(-164deg) rotateY(39deg) translateX(200px) rotateZ(164deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-164deg) rotateY(39deg) translateX(600px) rotateZ(164deg);
    }
  }
  .c:nth-child(194) {
    animation: orbit194 14s infinite;
    animation-delay: 1.94s;
    background-color: #ff6e00;
  }
  
  @keyframes orbit194 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-142deg) rotateY(105deg) translateX(200px) rotateZ(142deg);
    }
    80% {
      transform: rotateZ(-142deg) rotateY(105deg) translateX(200px) rotateZ(142deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-142deg) rotateY(105deg) translateX(600px) rotateZ(142deg);
    }
  }
  .c:nth-child(195) {
    animation: orbit195 14s infinite;
    animation-delay: 1.95s;
    background-color: #ff6f00;
  }
  
  @keyframes orbit195 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-220deg) rotateY(320deg) translateX(200px) rotateZ(220deg);
    }
    80% {
      transform: rotateZ(-220deg) rotateY(320deg) translateX(200px) rotateZ(220deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-220deg) rotateY(320deg) translateX(600px) rotateZ(220deg);
    }
  }
  .c:nth-child(196) {
    animation: orbit196 14s infinite;
    animation-delay: 1.96s;
    background-color: #ff6f00;
  }
  
  @keyframes orbit196 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-327deg) rotateY(39deg) translateX(200px) rotateZ(327deg);
    }
    80% {
      transform: rotateZ(-327deg) rotateY(39deg) translateX(200px) rotateZ(327deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-327deg) rotateY(39deg) translateX(600px) rotateZ(327deg);
    }
  }
  .c:nth-child(197) {
    animation: orbit197 14s infinite;
    animation-delay: 1.97s;
    background-color: #ff7000;
  }
  
  @keyframes orbit197 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-346deg) rotateY(30deg) translateX(200px) rotateZ(346deg);
    }
    80% {
      transform: rotateZ(-346deg) rotateY(30deg) translateX(200px) rotateZ(346deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-346deg) rotateY(30deg) translateX(600px) rotateZ(346deg);
    }
  }
  .c:nth-child(198) {
    animation: orbit198 14s infinite;
    animation-delay: 1.98s;
    background-color: #ff7000;
  }
  
  @keyframes orbit198 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-166deg) rotateY(216deg) translateX(200px) rotateZ(166deg);
    }
    80% {
      transform: rotateZ(-166deg) rotateY(216deg) translateX(200px) rotateZ(166deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-166deg) rotateY(216deg) translateX(600px) rotateZ(166deg);
    }
  }
  .c:nth-child(199) {
    animation: orbit199 14s infinite;
    animation-delay: 1.99s;
    background-color: #ff7100;
  }
  
  @keyframes orbit199 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-131deg) rotateY(333deg) translateX(200px) rotateZ(131deg);
    }
    80% {
      transform: rotateZ(-131deg) rotateY(333deg) translateX(200px) rotateZ(131deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-131deg) rotateY(333deg) translateX(600px) rotateZ(131deg);
    }
  }
  .c:nth-child(200) {
    animation: orbit200 14s infinite;
    animation-delay: 2s;
    background-color: #ff7100;
  }
  
  @keyframes orbit200 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-18deg) rotateY(230deg) translateX(200px) rotateZ(18deg);
    }
    80% {
      transform: rotateZ(-18deg) rotateY(230deg) translateX(200px) rotateZ(18deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-18deg) rotateY(230deg) translateX(600px) rotateZ(18deg);
    }
  }
  .c:nth-child(201) {
    animation: orbit201 14s infinite;
    animation-delay: 2.01s;
    background-color: #ff7200;
  }
  
  @keyframes orbit201 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-192deg) rotateY(193deg) translateX(200px) rotateZ(192deg);
    }
    80% {
      transform: rotateZ(-192deg) rotateY(193deg) translateX(200px) rotateZ(192deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-192deg) rotateY(193deg) translateX(600px) rotateZ(192deg);
    }
  }
  .c:nth-child(202) {
    animation: orbit202 14s infinite;
    animation-delay: 2.02s;
    background-color: #ff7200;
  }
  
  @keyframes orbit202 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-198deg) rotateY(297deg) translateX(200px) rotateZ(198deg);
    }
    80% {
      transform: rotateZ(-198deg) rotateY(297deg) translateX(200px) rotateZ(198deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-198deg) rotateY(297deg) translateX(600px) rotateZ(198deg);
    }
  }
  .c:nth-child(203) {
    animation: orbit203 14s infinite;
    animation-delay: 2.03s;
    background-color: #ff7300;
  }
  
  @keyframes orbit203 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-49deg) rotateY(71deg) translateX(200px) rotateZ(49deg);
    }
    80% {
      transform: rotateZ(-49deg) rotateY(71deg) translateX(200px) rotateZ(49deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-49deg) rotateY(71deg) translateX(600px) rotateZ(49deg);
    }
  }
  .c:nth-child(204) {
    animation: orbit204 14s infinite;
    animation-delay: 2.04s;
    background-color: #ff7400;
  }
  
  @keyframes orbit204 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-332deg) rotateY(36deg) translateX(200px) rotateZ(332deg);
    }
    80% {
      transform: rotateZ(-332deg) rotateY(36deg) translateX(200px) rotateZ(332deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-332deg) rotateY(36deg) translateX(600px) rotateZ(332deg);
    }
  }
  .c:nth-child(205) {
    animation: orbit205 14s infinite;
    animation-delay: 2.05s;
    background-color: #ff7400;
  }
  
  @keyframes orbit205 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-165deg) rotateY(42deg) translateX(200px) rotateZ(165deg);
    }
    80% {
      transform: rotateZ(-165deg) rotateY(42deg) translateX(200px) rotateZ(165deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-165deg) rotateY(42deg) translateX(600px) rotateZ(165deg);
    }
  }
  .c:nth-child(206) {
    animation: orbit206 14s infinite;
    animation-delay: 2.06s;
    background-color: #ff7500;
  }
  
  @keyframes orbit206 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-288deg) rotateY(119deg) translateX(200px) rotateZ(288deg);
    }
    80% {
      transform: rotateZ(-288deg) rotateY(119deg) translateX(200px) rotateZ(288deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-288deg) rotateY(119deg) translateX(600px) rotateZ(288deg);
    }
  }
  .c:nth-child(207) {
    animation: orbit207 14s infinite;
    animation-delay: 2.07s;
    background-color: #ff7500;
  }
  
  @keyframes orbit207 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-229deg) rotateY(45deg) translateX(200px) rotateZ(229deg);
    }
    80% {
      transform: rotateZ(-229deg) rotateY(45deg) translateX(200px) rotateZ(229deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-229deg) rotateY(45deg) translateX(600px) rotateZ(229deg);
    }
  }
  .c:nth-child(208) {
    animation: orbit208 14s infinite;
    animation-delay: 2.08s;
    background-color: #ff7600;
  }
  
  @keyframes orbit208 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-128deg) rotateY(311deg) translateX(200px) rotateZ(128deg);
    }
    80% {
      transform: rotateZ(-128deg) rotateY(311deg) translateX(200px) rotateZ(128deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-128deg) rotateY(311deg) translateX(600px) rotateZ(128deg);
    }
  }
  .c:nth-child(209) {
    animation: orbit209 14s infinite;
    animation-delay: 2.09s;
    background-color: #ff7600;
  }
  
  @keyframes orbit209 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-300deg) rotateY(166deg) translateX(200px) rotateZ(300deg);
    }
    80% {
      transform: rotateZ(-300deg) rotateY(166deg) translateX(200px) rotateZ(300deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-300deg) rotateY(166deg) translateX(600px) rotateZ(300deg);
    }
  }
  .c:nth-child(210) {
    animation: orbit210 14s infinite;
    animation-delay: 2.1s;
    background-color: #ff7700;
  }
  
  @keyframes orbit210 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-140deg) rotateY(285deg) translateX(200px) rotateZ(140deg);
    }
    80% {
      transform: rotateZ(-140deg) rotateY(285deg) translateX(200px) rotateZ(140deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-140deg) rotateY(285deg) translateX(600px) rotateZ(140deg);
    }
  }
  .c:nth-child(211) {
    animation: orbit211 14s infinite;
    animation-delay: 2.11s;
    background-color: #ff7800;
  }
  
  @keyframes orbit211 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-265deg) rotateY(233deg) translateX(200px) rotateZ(265deg);
    }
    80% {
      transform: rotateZ(-265deg) rotateY(233deg) translateX(200px) rotateZ(265deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-265deg) rotateY(233deg) translateX(600px) rotateZ(265deg);
    }
  }
  .c:nth-child(212) {
    animation: orbit212 14s infinite;
    animation-delay: 2.12s;
    background-color: #ff7800;
  }
  
  @keyframes orbit212 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-16deg) rotateY(256deg) translateX(200px) rotateZ(16deg);
    }
    80% {
      transform: rotateZ(-16deg) rotateY(256deg) translateX(200px) rotateZ(16deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-16deg) rotateY(256deg) translateX(600px) rotateZ(16deg);
    }
  }
  .c:nth-child(213) {
    animation: orbit213 14s infinite;
    animation-delay: 2.13s;
    background-color: #ff7900;
  }
  
  @keyframes orbit213 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-266deg) rotateY(32deg) translateX(200px) rotateZ(266deg);
    }
    80% {
      transform: rotateZ(-266deg) rotateY(32deg) translateX(200px) rotateZ(266deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-266deg) rotateY(32deg) translateX(600px) rotateZ(266deg);
    }
  }
  .c:nth-child(214) {
    animation: orbit214 14s infinite;
    animation-delay: 2.14s;
    background-color: #ff7900;
  }
  
  @keyframes orbit214 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-331deg) rotateY(62deg) translateX(200px) rotateZ(331deg);
    }
    80% {
      transform: rotateZ(-331deg) rotateY(62deg) translateX(200px) rotateZ(331deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-331deg) rotateY(62deg) translateX(600px) rotateZ(331deg);
    }
  }
  .c:nth-child(215) {
    animation: orbit215 14s infinite;
    animation-delay: 2.15s;
    background-color: #ff7a00;
  }
  
  @keyframes orbit215 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-282deg) rotateY(96deg) translateX(200px) rotateZ(282deg);
    }
    80% {
      transform: rotateZ(-282deg) rotateY(96deg) translateX(200px) rotateZ(282deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-282deg) rotateY(96deg) translateX(600px) rotateZ(282deg);
    }
  }
  .c:nth-child(216) {
    animation: orbit216 14s infinite;
    animation-delay: 2.16s;
    background-color: #ff7a00;
  }
  
  @keyframes orbit216 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-206deg) rotateY(185deg) translateX(200px) rotateZ(206deg);
    }
    80% {
      transform: rotateZ(-206deg) rotateY(185deg) translateX(200px) rotateZ(206deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-206deg) rotateY(185deg) translateX(600px) rotateZ(206deg);
    }
  }
  .c:nth-child(217) {
    animation: orbit217 14s infinite;
    animation-delay: 2.17s;
    background-color: #ff7b00;
  }
  
  @keyframes orbit217 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-235deg) rotateY(316deg) translateX(200px) rotateZ(235deg);
    }
    80% {
      transform: rotateZ(-235deg) rotateY(316deg) translateX(200px) rotateZ(235deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-235deg) rotateY(316deg) translateX(600px) rotateZ(235deg);
    }
  }
  .c:nth-child(218) {
    animation: orbit218 14s infinite;
    animation-delay: 2.18s;
    background-color: #ff7c00;
  }
  
  @keyframes orbit218 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-199deg) rotateY(330deg) translateX(200px) rotateZ(199deg);
    }
    80% {
      transform: rotateZ(-199deg) rotateY(330deg) translateX(200px) rotateZ(199deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-199deg) rotateY(330deg) translateX(600px) rotateZ(199deg);
    }
  }
  .c:nth-child(219) {
    animation: orbit219 14s infinite;
    animation-delay: 2.19s;
    background-color: #ff7c00;
  }
  
  @keyframes orbit219 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-195deg) rotateY(118deg) translateX(200px) rotateZ(195deg);
    }
    80% {
      transform: rotateZ(-195deg) rotateY(118deg) translateX(200px) rotateZ(195deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-195deg) rotateY(118deg) translateX(600px) rotateZ(195deg);
    }
  }
  .c:nth-child(220) {
    animation: orbit220 14s infinite;
    animation-delay: 2.2s;
    background-color: #ff7d00;
  }
  
  @keyframes orbit220 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-312deg) rotateY(166deg) translateX(200px) rotateZ(312deg);
    }
    80% {
      transform: rotateZ(-312deg) rotateY(166deg) translateX(200px) rotateZ(312deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-312deg) rotateY(166deg) translateX(600px) rotateZ(312deg);
    }
  }
  .c:nth-child(221) {
    animation: orbit221 14s infinite;
    animation-delay: 2.21s;
    background-color: #ff7d00;
  }
  
  @keyframes orbit221 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-284deg) rotateY(10deg) translateX(200px) rotateZ(284deg);
    }
    80% {
      transform: rotateZ(-284deg) rotateY(10deg) translateX(200px) rotateZ(284deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-284deg) rotateY(10deg) translateX(600px) rotateZ(284deg);
    }
  }
  .c:nth-child(222) {
    animation: orbit222 14s infinite;
    animation-delay: 2.22s;
    background-color: #ff7e00;
  }
  
  @keyframes orbit222 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-99deg) rotateY(329deg) translateX(200px) rotateZ(99deg);
    }
    80% {
      transform: rotateZ(-99deg) rotateY(329deg) translateX(200px) rotateZ(99deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-99deg) rotateY(329deg) translateX(600px) rotateZ(99deg);
    }
  }
  .c:nth-child(223) {
    animation: orbit223 14s infinite;
    animation-delay: 2.23s;
    background-color: #ff7e00;
  }
  
  @keyframes orbit223 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-240deg) rotateY(263deg) translateX(200px) rotateZ(240deg);
    }
    80% {
      transform: rotateZ(-240deg) rotateY(263deg) translateX(200px) rotateZ(240deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-240deg) rotateY(263deg) translateX(600px) rotateZ(240deg);
    }
  }
  .c:nth-child(224) {
    animation: orbit224 14s infinite;
    animation-delay: 2.24s;
    background-color: #ff7f00;
  }
  
  @keyframes orbit224 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-282deg) rotateY(31deg) translateX(200px) rotateZ(282deg);
    }
    80% {
      transform: rotateZ(-282deg) rotateY(31deg) translateX(200px) rotateZ(282deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-282deg) rotateY(31deg) translateX(600px) rotateZ(282deg);
    }
  }
  .c:nth-child(225) {
    animation: orbit225 14s infinite;
    animation-delay: 2.25s;
    background-color: #ff8000;
  }
  
  @keyframes orbit225 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-232deg) rotateY(26deg) translateX(200px) rotateZ(232deg);
    }
    80% {
      transform: rotateZ(-232deg) rotateY(26deg) translateX(200px) rotateZ(232deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-232deg) rotateY(26deg) translateX(600px) rotateZ(232deg);
    }
  }
  .c:nth-child(226) {
    animation: orbit226 14s infinite;
    animation-delay: 2.26s;
    background-color: #ff8000;
  }
  
  @keyframes orbit226 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-157deg) rotateY(180deg) translateX(200px) rotateZ(157deg);
    }
    80% {
      transform: rotateZ(-157deg) rotateY(180deg) translateX(200px) rotateZ(157deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-157deg) rotateY(180deg) translateX(600px) rotateZ(157deg);
    }
  }
  .c:nth-child(227) {
    animation: orbit227 14s infinite;
    animation-delay: 2.27s;
    background-color: #ff8100;
  }
  
  @keyframes orbit227 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-34deg) rotateY(253deg) translateX(200px) rotateZ(34deg);
    }
    80% {
      transform: rotateZ(-34deg) rotateY(253deg) translateX(200px) rotateZ(34deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-34deg) rotateY(253deg) translateX(600px) rotateZ(34deg);
    }
  }
  .c:nth-child(228) {
    animation: orbit228 14s infinite;
    animation-delay: 2.28s;
    background-color: #ff8100;
  }
  
  @keyframes orbit228 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-166deg) rotateY(92deg) translateX(200px) rotateZ(166deg);
    }
    80% {
      transform: rotateZ(-166deg) rotateY(92deg) translateX(200px) rotateZ(166deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-166deg) rotateY(92deg) translateX(600px) rotateZ(166deg);
    }
  }
  .c:nth-child(229) {
    animation: orbit229 14s infinite;
    animation-delay: 2.29s;
    background-color: #ff8200;
  }
  
  @keyframes orbit229 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-32deg) rotateY(146deg) translateX(200px) rotateZ(32deg);
    }
    80% {
      transform: rotateZ(-32deg) rotateY(146deg) translateX(200px) rotateZ(32deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-32deg) rotateY(146deg) translateX(600px) rotateZ(32deg);
    }
  }
  .c:nth-child(230) {
    animation: orbit230 14s infinite;
    animation-delay: 2.3s;
    background-color: #ff8200;
  }
  
  @keyframes orbit230 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-209deg) rotateY(352deg) translateX(200px) rotateZ(209deg);
    }
    80% {
      transform: rotateZ(-209deg) rotateY(352deg) translateX(200px) rotateZ(209deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-209deg) rotateY(352deg) translateX(600px) rotateZ(209deg);
    }
  }
  .c:nth-child(231) {
    animation: orbit231 14s infinite;
    animation-delay: 2.31s;
    background-color: #ff8300;
  }
  
  @keyframes orbit231 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-301deg) rotateY(175deg) translateX(200px) rotateZ(301deg);
    }
    80% {
      transform: rotateZ(-301deg) rotateY(175deg) translateX(200px) rotateZ(301deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-301deg) rotateY(175deg) translateX(600px) rotateZ(301deg);
    }
  }
  .c:nth-child(232) {
    animation: orbit232 14s infinite;
    animation-delay: 2.32s;
    background-color: #ff8300;
  }
  
  @keyframes orbit232 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-173deg) rotateY(150deg) translateX(200px) rotateZ(173deg);
    }
    80% {
      transform: rotateZ(-173deg) rotateY(150deg) translateX(200px) rotateZ(173deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-173deg) rotateY(150deg) translateX(600px) rotateZ(173deg);
    }
  }
  .c:nth-child(233) {
    animation: orbit233 14s infinite;
    animation-delay: 2.33s;
    background-color: #ff8400;
  }
  
  @keyframes orbit233 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-337deg) rotateY(94deg) translateX(200px) rotateZ(337deg);
    }
    80% {
      transform: rotateZ(-337deg) rotateY(94deg) translateX(200px) rotateZ(337deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-337deg) rotateY(94deg) translateX(600px) rotateZ(337deg);
    }
  }
  .c:nth-child(234) {
    animation: orbit234 14s infinite;
    animation-delay: 2.34s;
    background-color: #ff8500;
  }
  
  @keyframes orbit234 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-309deg) rotateY(353deg) translateX(200px) rotateZ(309deg);
    }
    80% {
      transform: rotateZ(-309deg) rotateY(353deg) translateX(200px) rotateZ(309deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-309deg) rotateY(353deg) translateX(600px) rotateZ(309deg);
    }
  }
  .c:nth-child(235) {
    animation: orbit235 14s infinite;
    animation-delay: 2.35s;
    background-color: #ff8500;
  }
  
  @keyframes orbit235 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-243deg) rotateY(173deg) translateX(200px) rotateZ(243deg);
    }
    80% {
      transform: rotateZ(-243deg) rotateY(173deg) translateX(200px) rotateZ(243deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-243deg) rotateY(173deg) translateX(600px) rotateZ(243deg);
    }
  }
  .c:nth-child(236) {
    animation: orbit236 14s infinite;
    animation-delay: 2.36s;
    background-color: #ff8600;
  }
  
  @keyframes orbit236 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-1deg) rotateY(212deg) translateX(200px) rotateZ(1deg);
    }
    80% {
      transform: rotateZ(-1deg) rotateY(212deg) translateX(200px) rotateZ(1deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-1deg) rotateY(212deg) translateX(600px) rotateZ(1deg);
    }
  }
  .c:nth-child(237) {
    animation: orbit237 14s infinite;
    animation-delay: 2.37s;
    background-color: #ff8600;
  }
  
  @keyframes orbit237 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-64deg) rotateY(196deg) translateX(200px) rotateZ(64deg);
    }
    80% {
      transform: rotateZ(-64deg) rotateY(196deg) translateX(200px) rotateZ(64deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-64deg) rotateY(196deg) translateX(600px) rotateZ(64deg);
    }
  }
  .c:nth-child(238) {
    animation: orbit238 14s infinite;
    animation-delay: 2.38s;
    background-color: #ff8700;
  }
  
  @keyframes orbit238 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-186deg) rotateY(317deg) translateX(200px) rotateZ(186deg);
    }
    80% {
      transform: rotateZ(-186deg) rotateY(317deg) translateX(200px) rotateZ(186deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-186deg) rotateY(317deg) translateX(600px) rotateZ(186deg);
    }
  }
  .c:nth-child(239) {
    animation: orbit239 14s infinite;
    animation-delay: 2.39s;
    background-color: #ff8700;
  }
  
  @keyframes orbit239 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-244deg) rotateY(205deg) translateX(200px) rotateZ(244deg);
    }
    80% {
      transform: rotateZ(-244deg) rotateY(205deg) translateX(200px) rotateZ(244deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-244deg) rotateY(205deg) translateX(600px) rotateZ(244deg);
    }
  }
  .c:nth-child(240) {
    animation: orbit240 14s infinite;
    animation-delay: 2.4s;
    background-color: #ff8800;
  }
  
  @keyframes orbit240 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-234deg) rotateY(263deg) translateX(200px) rotateZ(234deg);
    }
    80% {
      transform: rotateZ(-234deg) rotateY(263deg) translateX(200px) rotateZ(234deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-234deg) rotateY(263deg) translateX(600px) rotateZ(234deg);
    }
  }
  .c:nth-child(241) {
    animation: orbit241 14s infinite;
    animation-delay: 2.41s;
    background-color: #ff8900;
  }
  
  @keyframes orbit241 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-7deg) rotateY(195deg) translateX(200px) rotateZ(7deg);
    }
    80% {
      transform: rotateZ(-7deg) rotateY(195deg) translateX(200px) rotateZ(7deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-7deg) rotateY(195deg) translateX(600px) rotateZ(7deg);
    }
  }
  .c:nth-child(242) {
    animation: orbit242 14s infinite;
    animation-delay: 2.42s;
    background-color: #ff8900;
  }
  
  @keyframes orbit242 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-33deg) rotateY(306deg) translateX(200px) rotateZ(33deg);
    }
    80% {
      transform: rotateZ(-33deg) rotateY(306deg) translateX(200px) rotateZ(33deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-33deg) rotateY(306deg) translateX(600px) rotateZ(33deg);
    }
  }
  .c:nth-child(243) {
    animation: orbit243 14s infinite;
    animation-delay: 2.43s;
    background-color: #ff8a00;
  }
  
  @keyframes orbit243 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-241deg) rotateY(328deg) translateX(200px) rotateZ(241deg);
    }
    80% {
      transform: rotateZ(-241deg) rotateY(328deg) translateX(200px) rotateZ(241deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-241deg) rotateY(328deg) translateX(600px) rotateZ(241deg);
    }
  }
  .c:nth-child(244) {
    animation: orbit244 14s infinite;
    animation-delay: 2.44s;
    background-color: #ff8a00;
  }
  
  @keyframes orbit244 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-123deg) rotateY(14deg) translateX(200px) rotateZ(123deg);
    }
    80% {
      transform: rotateZ(-123deg) rotateY(14deg) translateX(200px) rotateZ(123deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-123deg) rotateY(14deg) translateX(600px) rotateZ(123deg);
    }
  }
  .c:nth-child(245) {
    animation: orbit245 14s infinite;
    animation-delay: 2.45s;
    background-color: #ff8b00;
  }
  
  @keyframes orbit245 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-111deg) rotateY(190deg) translateX(200px) rotateZ(111deg);
    }
    80% {
      transform: rotateZ(-111deg) rotateY(190deg) translateX(200px) rotateZ(111deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-111deg) rotateY(190deg) translateX(600px) rotateZ(111deg);
    }
  }
  .c:nth-child(246) {
    animation: orbit246 14s infinite;
    animation-delay: 2.46s;
    background-color: #ff8b00;
  }
  
  @keyframes orbit246 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-133deg) rotateY(184deg) translateX(200px) rotateZ(133deg);
    }
    80% {
      transform: rotateZ(-133deg) rotateY(184deg) translateX(200px) rotateZ(133deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-133deg) rotateY(184deg) translateX(600px) rotateZ(133deg);
    }
  }
  .c:nth-child(247) {
    animation: orbit247 14s infinite;
    animation-delay: 2.47s;
    background-color: darkorange;
  }
  
  @keyframes orbit247 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-265deg) rotateY(299deg) translateX(200px) rotateZ(265deg);
    }
    80% {
      transform: rotateZ(-265deg) rotateY(299deg) translateX(200px) rotateZ(265deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-265deg) rotateY(299deg) translateX(600px) rotateZ(265deg);
    }
  }
  .c:nth-child(248) {
    animation: orbit248 14s infinite;
    animation-delay: 2.48s;
    background-color: #ff8d00;
  }
  
  @keyframes orbit248 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-280deg) rotateY(247deg) translateX(200px) rotateZ(280deg);
    }
    80% {
      transform: rotateZ(-280deg) rotateY(247deg) translateX(200px) rotateZ(280deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-280deg) rotateY(247deg) translateX(600px) rotateZ(280deg);
    }
  }
  .c:nth-child(249) {
    animation: orbit249 14s infinite;
    animation-delay: 2.49s;
    background-color: #ff8d00;
  }
  
  @keyframes orbit249 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-12deg) rotateY(204deg) translateX(200px) rotateZ(12deg);
    }
    80% {
      transform: rotateZ(-12deg) rotateY(204deg) translateX(200px) rotateZ(12deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-12deg) rotateY(204deg) translateX(600px) rotateZ(12deg);
    }
  }
  .c:nth-child(250) {
    animation: orbit250 14s infinite;
    animation-delay: 2.5s;
    background-color: #ff8e00;
  }
  
  @keyframes orbit250 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-350deg) rotateY(35deg) translateX(200px) rotateZ(350deg);
    }
    80% {
      transform: rotateZ(-350deg) rotateY(35deg) translateX(200px) rotateZ(350deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-350deg) rotateY(35deg) translateX(600px) rotateZ(350deg);
    }
  }
  .c:nth-child(251) {
    animation: orbit251 14s infinite;
    animation-delay: 2.51s;
    background-color: #ff8e00;
  }
  
  @keyframes orbit251 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-205deg) rotateY(174deg) translateX(200px) rotateZ(205deg);
    }
    80% {
      transform: rotateZ(-205deg) rotateY(174deg) translateX(200px) rotateZ(205deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-205deg) rotateY(174deg) translateX(600px) rotateZ(205deg);
    }
  }
  .c:nth-child(252) {
    animation: orbit252 14s infinite;
    animation-delay: 2.52s;
    background-color: #ff8f00;
  }
  
  @keyframes orbit252 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-294deg) rotateY(246deg) translateX(200px) rotateZ(294deg);
    }
    80% {
      transform: rotateZ(-294deg) rotateY(246deg) translateX(200px) rotateZ(294deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-294deg) rotateY(246deg) translateX(600px) rotateZ(294deg);
    }
  }
  .c:nth-child(253) {
    animation: orbit253 14s infinite;
    animation-delay: 2.53s;
    background-color: #ff8f00;
  }
  
  @keyframes orbit253 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-296deg) rotateY(78deg) translateX(200px) rotateZ(296deg);
    }
    80% {
      transform: rotateZ(-296deg) rotateY(78deg) translateX(200px) rotateZ(296deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-296deg) rotateY(78deg) translateX(600px) rotateZ(296deg);
    }
  }
  .c:nth-child(254) {
    animation: orbit254 14s infinite;
    animation-delay: 2.54s;
    background-color: #ff9000;
  }
  
  @keyframes orbit254 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-290deg) rotateY(266deg) translateX(200px) rotateZ(290deg);
    }
    80% {
      transform: rotateZ(-290deg) rotateY(266deg) translateX(200px) rotateZ(290deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-290deg) rotateY(266deg) translateX(600px) rotateZ(290deg);
    }
  }
  .c:nth-child(255) {
    animation: orbit255 14s infinite;
    animation-delay: 2.55s;
    background-color: #ff9100;
  }
  
  @keyframes orbit255 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-91deg) rotateY(55deg) translateX(200px) rotateZ(91deg);
    }
    80% {
      transform: rotateZ(-91deg) rotateY(55deg) translateX(200px) rotateZ(91deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-91deg) rotateY(55deg) translateX(600px) rotateZ(91deg);
    }
  }
  .c:nth-child(256) {
    animation: orbit256 14s infinite;
    animation-delay: 2.56s;
    background-color: #ff9100;
  }
  
  @keyframes orbit256 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-233deg) rotateY(360deg) translateX(200px) rotateZ(233deg);
    }
    80% {
      transform: rotateZ(-233deg) rotateY(360deg) translateX(200px) rotateZ(233deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-233deg) rotateY(360deg) translateX(600px) rotateZ(233deg);
    }
  }
  .c:nth-child(257) {
    animation: orbit257 14s infinite;
    animation-delay: 2.57s;
    background-color: #ff9200;
  }
  
  @keyframes orbit257 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-198deg) rotateY(359deg) translateX(200px) rotateZ(198deg);
    }
    80% {
      transform: rotateZ(-198deg) rotateY(359deg) translateX(200px) rotateZ(198deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-198deg) rotateY(359deg) translateX(600px) rotateZ(198deg);
    }
  }
  .c:nth-child(258) {
    animation: orbit258 14s infinite;
    animation-delay: 2.58s;
    background-color: #ff9200;
  }
  
  @keyframes orbit258 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-312deg) rotateY(334deg) translateX(200px) rotateZ(312deg);
    }
    80% {
      transform: rotateZ(-312deg) rotateY(334deg) translateX(200px) rotateZ(312deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-312deg) rotateY(334deg) translateX(600px) rotateZ(312deg);
    }
  }
  .c:nth-child(259) {
    animation: orbit259 14s infinite;
    animation-delay: 2.59s;
    background-color: #ff9300;
  }
  
  @keyframes orbit259 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-304deg) rotateY(8deg) translateX(200px) rotateZ(304deg);
    }
    80% {
      transform: rotateZ(-304deg) rotateY(8deg) translateX(200px) rotateZ(304deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-304deg) rotateY(8deg) translateX(600px) rotateZ(304deg);
    }
  }
  .c:nth-child(260) {
    animation: orbit260 14s infinite;
    animation-delay: 2.6s;
    background-color: #ff9300;
  }
  
  @keyframes orbit260 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-319deg) rotateY(87deg) translateX(200px) rotateZ(319deg);
    }
    80% {
      transform: rotateZ(-319deg) rotateY(87deg) translateX(200px) rotateZ(319deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-319deg) rotateY(87deg) translateX(600px) rotateZ(319deg);
    }
  }
  .c:nth-child(261) {
    animation: orbit261 14s infinite;
    animation-delay: 2.61s;
    background-color: #ff9400;
  }
  
  @keyframes orbit261 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-246deg) rotateY(217deg) translateX(200px) rotateZ(246deg);
    }
    80% {
      transform: rotateZ(-246deg) rotateY(217deg) translateX(200px) rotateZ(246deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-246deg) rotateY(217deg) translateX(600px) rotateZ(246deg);
    }
  }
  .c:nth-child(262) {
    animation: orbit262 14s infinite;
    animation-delay: 2.62s;
    background-color: #ff9400;
  }
  
  @keyframes orbit262 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-344deg) rotateY(55deg) translateX(200px) rotateZ(344deg);
    }
    80% {
      transform: rotateZ(-344deg) rotateY(55deg) translateX(200px) rotateZ(344deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-344deg) rotateY(55deg) translateX(600px) rotateZ(344deg);
    }
  }
  .c:nth-child(263) {
    animation: orbit263 14s infinite;
    animation-delay: 2.63s;
    background-color: #ff9500;
  }
  
  @keyframes orbit263 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-268deg) rotateY(135deg) translateX(200px) rotateZ(268deg);
    }
    80% {
      transform: rotateZ(-268deg) rotateY(135deg) translateX(200px) rotateZ(268deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-268deg) rotateY(135deg) translateX(600px) rotateZ(268deg);
    }
  }
  .c:nth-child(264) {
    animation: orbit264 14s infinite;
    animation-delay: 2.64s;
    background-color: #ff9600;
  }
  
  @keyframes orbit264 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-172deg) rotateY(222deg) translateX(200px) rotateZ(172deg);
    }
    80% {
      transform: rotateZ(-172deg) rotateY(222deg) translateX(200px) rotateZ(172deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-172deg) rotateY(222deg) translateX(600px) rotateZ(172deg);
    }
  }
  .c:nth-child(265) {
    animation: orbit265 14s infinite;
    animation-delay: 2.65s;
    background-color: #ff9600;
  }
  
  @keyframes orbit265 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-91deg) rotateY(216deg) translateX(200px) rotateZ(91deg);
    }
    80% {
      transform: rotateZ(-91deg) rotateY(216deg) translateX(200px) rotateZ(91deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-91deg) rotateY(216deg) translateX(600px) rotateZ(91deg);
    }
  }
  .c:nth-child(266) {
    animation: orbit266 14s infinite;
    animation-delay: 2.66s;
    background-color: #ff9700;
  }
  
  @keyframes orbit266 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-311deg) rotateY(261deg) translateX(200px) rotateZ(311deg);
    }
    80% {
      transform: rotateZ(-311deg) rotateY(261deg) translateX(200px) rotateZ(311deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-311deg) rotateY(261deg) translateX(600px) rotateZ(311deg);
    }
  }
  .c:nth-child(267) {
    animation: orbit267 14s infinite;
    animation-delay: 2.67s;
    background-color: #ff9700;
  }
  
  @keyframes orbit267 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-144deg) rotateY(227deg) translateX(200px) rotateZ(144deg);
    }
    80% {
      transform: rotateZ(-144deg) rotateY(227deg) translateX(200px) rotateZ(144deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-144deg) rotateY(227deg) translateX(600px) rotateZ(144deg);
    }
  }
  .c:nth-child(268) {
    animation: orbit268 14s infinite;
    animation-delay: 2.68s;
    background-color: #ff9800;
  }
  
  @keyframes orbit268 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-223deg) rotateY(10deg) translateX(200px) rotateZ(223deg);
    }
    80% {
      transform: rotateZ(-223deg) rotateY(10deg) translateX(200px) rotateZ(223deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-223deg) rotateY(10deg) translateX(600px) rotateZ(223deg);
    }
  }
  .c:nth-child(269) {
    animation: orbit269 14s infinite;
    animation-delay: 2.69s;
    background-color: #ff9800;
  }
  
  @keyframes orbit269 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-162deg) rotateY(133deg) translateX(200px) rotateZ(162deg);
    }
    80% {
      transform: rotateZ(-162deg) rotateY(133deg) translateX(200px) rotateZ(162deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-162deg) rotateY(133deg) translateX(600px) rotateZ(162deg);
    }
  }
  .c:nth-child(270) {
    animation: orbit270 14s infinite;
    animation-delay: 2.7s;
    background-color: #ff9900;
  }
  
  @keyframes orbit270 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-105deg) rotateY(296deg) translateX(200px) rotateZ(105deg);
    }
    80% {
      transform: rotateZ(-105deg) rotateY(296deg) translateX(200px) rotateZ(105deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-105deg) rotateY(296deg) translateX(600px) rotateZ(105deg);
    }
  }
  .c:nth-child(271) {
    animation: orbit271 14s infinite;
    animation-delay: 2.71s;
    background-color: #ff9a00;
  }
  
  @keyframes orbit271 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-23deg) rotateY(158deg) translateX(200px) rotateZ(23deg);
    }
    80% {
      transform: rotateZ(-23deg) rotateY(158deg) translateX(200px) rotateZ(23deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-23deg) rotateY(158deg) translateX(600px) rotateZ(23deg);
    }
  }
  .c:nth-child(272) {
    animation: orbit272 14s infinite;
    animation-delay: 2.72s;
    background-color: #ff9a00;
  }
  
  @keyframes orbit272 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-168deg) rotateY(186deg) translateX(200px) rotateZ(168deg);
    }
    80% {
      transform: rotateZ(-168deg) rotateY(186deg) translateX(200px) rotateZ(168deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-168deg) rotateY(186deg) translateX(600px) rotateZ(168deg);
    }
  }
  .c:nth-child(273) {
    animation: orbit273 14s infinite;
    animation-delay: 2.73s;
    background-color: #ff9b00;
  }
  
  @keyframes orbit273 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-153deg) rotateY(230deg) translateX(200px) rotateZ(153deg);
    }
    80% {
      transform: rotateZ(-153deg) rotateY(230deg) translateX(200px) rotateZ(153deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-153deg) rotateY(230deg) translateX(600px) rotateZ(153deg);
    }
  }
  .c:nth-child(274) {
    animation: orbit274 14s infinite;
    animation-delay: 2.74s;
    background-color: #ff9b00;
  }
  
  @keyframes orbit274 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-150deg) rotateY(108deg) translateX(200px) rotateZ(150deg);
    }
    80% {
      transform: rotateZ(-150deg) rotateY(108deg) translateX(200px) rotateZ(150deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-150deg) rotateY(108deg) translateX(600px) rotateZ(150deg);
    }
  }
  .c:nth-child(275) {
    animation: orbit275 14s infinite;
    animation-delay: 2.75s;
    background-color: #ff9c00;
  }
  
  @keyframes orbit275 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-287deg) rotateY(75deg) translateX(200px) rotateZ(287deg);
    }
    80% {
      transform: rotateZ(-287deg) rotateY(75deg) translateX(200px) rotateZ(287deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-287deg) rotateY(75deg) translateX(600px) rotateZ(287deg);
    }
  }
  .c:nth-child(276) {
    animation: orbit276 14s infinite;
    animation-delay: 2.76s;
    background-color: #ff9c00;
  }
  
  @keyframes orbit276 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-131deg) rotateY(235deg) translateX(200px) rotateZ(131deg);
    }
    80% {
      transform: rotateZ(-131deg) rotateY(235deg) translateX(200px) rotateZ(131deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-131deg) rotateY(235deg) translateX(600px) rotateZ(131deg);
    }
  }
  .c:nth-child(277) {
    animation: orbit277 14s infinite;
    animation-delay: 2.77s;
    background-color: #ff9d00;
  }
  
  @keyframes orbit277 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-263deg) rotateY(108deg) translateX(200px) rotateZ(263deg);
    }
    80% {
      transform: rotateZ(-263deg) rotateY(108deg) translateX(200px) rotateZ(263deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-263deg) rotateY(108deg) translateX(600px) rotateZ(263deg);
    }
  }
  .c:nth-child(278) {
    animation: orbit278 14s infinite;
    animation-delay: 2.78s;
    background-color: #ff9e00;
  }
  
  @keyframes orbit278 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-184deg) rotateY(60deg) translateX(200px) rotateZ(184deg);
    }
    80% {
      transform: rotateZ(-184deg) rotateY(60deg) translateX(200px) rotateZ(184deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-184deg) rotateY(60deg) translateX(600px) rotateZ(184deg);
    }
  }
  .c:nth-child(279) {
    animation: orbit279 14s infinite;
    animation-delay: 2.79s;
    background-color: #ff9e00;
  }
  
  @keyframes orbit279 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-129deg) rotateY(117deg) translateX(200px) rotateZ(129deg);
    }
    80% {
      transform: rotateZ(-129deg) rotateY(117deg) translateX(200px) rotateZ(129deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-129deg) rotateY(117deg) translateX(600px) rotateZ(129deg);
    }
  }
  .c:nth-child(280) {
    animation: orbit280 14s infinite;
    animation-delay: 2.8s;
    background-color: #ff9f00;
  }
  
  @keyframes orbit280 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-241deg) rotateY(108deg) translateX(200px) rotateZ(241deg);
    }
    80% {
      transform: rotateZ(-241deg) rotateY(108deg) translateX(200px) rotateZ(241deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-241deg) rotateY(108deg) translateX(600px) rotateZ(241deg);
    }
  }
  .c:nth-child(281) {
    animation: orbit281 14s infinite;
    animation-delay: 2.81s;
    background-color: #ff9f00;
  }
  
  @keyframes orbit281 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-239deg) rotateY(251deg) translateX(200px) rotateZ(239deg);
    }
    80% {
      transform: rotateZ(-239deg) rotateY(251deg) translateX(200px) rotateZ(239deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-239deg) rotateY(251deg) translateX(600px) rotateZ(239deg);
    }
  }
  .c:nth-child(282) {
    animation: orbit282 14s infinite;
    animation-delay: 2.82s;
    background-color: #ffa000;
  }
  
  @keyframes orbit282 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-18deg) rotateY(128deg) translateX(200px) rotateZ(18deg);
    }
    80% {
      transform: rotateZ(-18deg) rotateY(128deg) translateX(200px) rotateZ(18deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-18deg) rotateY(128deg) translateX(600px) rotateZ(18deg);
    }
  }
  .c:nth-child(283) {
    animation: orbit283 14s infinite;
    animation-delay: 2.83s;
    background-color: #ffa000;
  }
  
  @keyframes orbit283 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-102deg) rotateY(331deg) translateX(200px) rotateZ(102deg);
    }
    80% {
      transform: rotateZ(-102deg) rotateY(331deg) translateX(200px) rotateZ(102deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-102deg) rotateY(331deg) translateX(600px) rotateZ(102deg);
    }
  }
  .c:nth-child(284) {
    animation: orbit284 14s infinite;
    animation-delay: 2.84s;
    background-color: #ffa100;
  }
  
  @keyframes orbit284 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-130deg) rotateY(152deg) translateX(200px) rotateZ(130deg);
    }
    80% {
      transform: rotateZ(-130deg) rotateY(152deg) translateX(200px) rotateZ(130deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-130deg) rotateY(152deg) translateX(600px) rotateZ(130deg);
    }
  }
  .c:nth-child(285) {
    animation: orbit285 14s infinite;
    animation-delay: 2.85s;
    background-color: #ffa200;
  }
  
  @keyframes orbit285 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-306deg) rotateY(229deg) translateX(200px) rotateZ(306deg);
    }
    80% {
      transform: rotateZ(-306deg) rotateY(229deg) translateX(200px) rotateZ(306deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-306deg) rotateY(229deg) translateX(600px) rotateZ(306deg);
    }
  }
  .c:nth-child(286) {
    animation: orbit286 14s infinite;
    animation-delay: 2.86s;
    background-color: #ffa200;
  }
  
  @keyframes orbit286 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-269deg) rotateY(310deg) translateX(200px) rotateZ(269deg);
    }
    80% {
      transform: rotateZ(-269deg) rotateY(310deg) translateX(200px) rotateZ(269deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-269deg) rotateY(310deg) translateX(600px) rotateZ(269deg);
    }
  }
  .c:nth-child(287) {
    animation: orbit287 14s infinite;
    animation-delay: 2.87s;
    background-color: #ffa300;
  }
  
  @keyframes orbit287 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-94deg) rotateY(158deg) translateX(200px) rotateZ(94deg);
    }
    80% {
      transform: rotateZ(-94deg) rotateY(158deg) translateX(200px) rotateZ(94deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-94deg) rotateY(158deg) translateX(600px) rotateZ(94deg);
    }
  }
  .c:nth-child(288) {
    animation: orbit288 14s infinite;
    animation-delay: 2.88s;
    background-color: #ffa300;
  }
  
  @keyframes orbit288 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-162deg) rotateY(103deg) translateX(200px) rotateZ(162deg);
    }
    80% {
      transform: rotateZ(-162deg) rotateY(103deg) translateX(200px) rotateZ(162deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-162deg) rotateY(103deg) translateX(600px) rotateZ(162deg);
    }
  }
  .c:nth-child(289) {
    animation: orbit289 14s infinite;
    animation-delay: 2.89s;
    background-color: #ffa400;
  }
  
  @keyframes orbit289 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-290deg) rotateY(91deg) translateX(200px) rotateZ(290deg);
    }
    80% {
      transform: rotateZ(-290deg) rotateY(91deg) translateX(200px) rotateZ(290deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-290deg) rotateY(91deg) translateX(600px) rotateZ(290deg);
    }
  }
  .c:nth-child(290) {
    animation: orbit290 14s infinite;
    animation-delay: 2.9s;
    background-color: #ffa400;
  }
  
  @keyframes orbit290 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-336deg) rotateY(88deg) translateX(200px) rotateZ(336deg);
    }
    80% {
      transform: rotateZ(-336deg) rotateY(88deg) translateX(200px) rotateZ(336deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-336deg) rotateY(88deg) translateX(600px) rotateZ(336deg);
    }
  }
  .c:nth-child(291) {
    animation: orbit291 14s infinite;
    animation-delay: 2.91s;
    background-color: orange;
  }
  
  @keyframes orbit291 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-338deg) rotateY(192deg) translateX(200px) rotateZ(338deg);
    }
    80% {
      transform: rotateZ(-338deg) rotateY(192deg) translateX(200px) rotateZ(338deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-338deg) rotateY(192deg) translateX(600px) rotateZ(338deg);
    }
  }
  .c:nth-child(292) {
    animation: orbit292 14s infinite;
    animation-delay: 2.92s;
    background-color: orange;
  }
  
  @keyframes orbit292 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-140deg) rotateY(96deg) translateX(200px) rotateZ(140deg);
    }
    80% {
      transform: rotateZ(-140deg) rotateY(96deg) translateX(200px) rotateZ(140deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-140deg) rotateY(96deg) translateX(600px) rotateZ(140deg);
    }
  }
  .c:nth-child(293) {
    animation: orbit293 14s infinite;
    animation-delay: 2.93s;
    background-color: #ffa600;
  }
  
  @keyframes orbit293 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-267deg) rotateY(289deg) translateX(200px) rotateZ(267deg);
    }
    80% {
      transform: rotateZ(-267deg) rotateY(289deg) translateX(200px) rotateZ(267deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-267deg) rotateY(289deg) translateX(600px) rotateZ(267deg);
    }
  }
  .c:nth-child(294) {
    animation: orbit294 14s infinite;
    animation-delay: 2.94s;
    background-color: #ffa700;
  }
  
  @keyframes orbit294 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-118deg) rotateY(196deg) translateX(200px) rotateZ(118deg);
    }
    80% {
      transform: rotateZ(-118deg) rotateY(196deg) translateX(200px) rotateZ(118deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-118deg) rotateY(196deg) translateX(600px) rotateZ(118deg);
    }
  }
  .c:nth-child(295) {
    animation: orbit295 14s infinite;
    animation-delay: 2.95s;
    background-color: #ffa700;
  }
  
  @keyframes orbit295 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-158deg) rotateY(212deg) translateX(200px) rotateZ(158deg);
    }
    80% {
      transform: rotateZ(-158deg) rotateY(212deg) translateX(200px) rotateZ(158deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-158deg) rotateY(212deg) translateX(600px) rotateZ(158deg);
    }
  }
  .c:nth-child(296) {
    animation: orbit296 14s infinite;
    animation-delay: 2.96s;
    background-color: #ffa800;
  }
  
  @keyframes orbit296 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-48deg) rotateY(160deg) translateX(200px) rotateZ(48deg);
    }
    80% {
      transform: rotateZ(-48deg) rotateY(160deg) translateX(200px) rotateZ(48deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-48deg) rotateY(160deg) translateX(600px) rotateZ(48deg);
    }
  }
  .c:nth-child(297) {
    animation: orbit297 14s infinite;
    animation-delay: 2.97s;
    background-color: #ffa800;
  }
  
  @keyframes orbit297 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-264deg) rotateY(284deg) translateX(200px) rotateZ(264deg);
    }
    80% {
      transform: rotateZ(-264deg) rotateY(284deg) translateX(200px) rotateZ(264deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-264deg) rotateY(284deg) translateX(600px) rotateZ(264deg);
    }
  }
  .c:nth-child(298) {
    animation: orbit298 14s infinite;
    animation-delay: 2.98s;
    background-color: #ffa900;
  }
  
  @keyframes orbit298 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-112deg) rotateY(155deg) translateX(200px) rotateZ(112deg);
    }
    80% {
      transform: rotateZ(-112deg) rotateY(155deg) translateX(200px) rotateZ(112deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-112deg) rotateY(155deg) translateX(600px) rotateZ(112deg);
    }
  }
  .c:nth-child(299) {
    animation: orbit299 14s infinite;
    animation-delay: 2.99s;
    background-color: #ffa900;
  }
  
  @keyframes orbit299 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-274deg) rotateY(320deg) translateX(200px) rotateZ(274deg);
    }
    80% {
      transform: rotateZ(-274deg) rotateY(320deg) translateX(200px) rotateZ(274deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-274deg) rotateY(320deg) translateX(600px) rotateZ(274deg);
    }
  }
  .c:nth-child(300) {
    animation: orbit300 14s infinite;
    animation-delay: 3s;
    background-color: #ffaa00;
  }
  
  @keyframes orbit300 {
    20% {
      opacity: 1;
    }
    30% {
      transform: rotateZ(-23deg) rotateY(47deg) translateX(200px) rotateZ(23deg);
    }
    80% {
      transform: rotateZ(-23deg) rotateY(47deg) translateX(200px) rotateZ(23deg);
      opacity: 1;
    }
    100% {
      transform: rotateZ(-23deg) rotateY(47deg) translateX(600px) rotateZ(23deg);
    }
  }