.kyc-dialog {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.275);
  backdrop-filter: blur(2px);
  z-index: 6000;
  top: 0;
}

.extra-blur{
  backdrop-filter: blur(10px) !important;
}

.kyc-dialog .welcome {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  color: rgb(27, 92, 203);
}

.kyc-dialog .name {
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: 500;
}

.kyc-dialog .dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
  background: white !important;
  border-radius: 10px;
  padding: 20px;
}

.kyc-dialog .steps-head {
  color: rgb(8, 1, 29);
  font-weight: 500;
}
.kyc-dialog .desc {
  color: gray;
  width: 90%;
  margin-top: 20px;
}
.kyc-dialog button {
  box-shadow: none !important;
  border-radius: 30px;
  padding: 10px 30px;
}

.loading-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}