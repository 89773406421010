.frame {
    background-color: red;
    border-radius: 2px;
    overflow: hidden;
  }
  
  .frame .overlap {
    height: 151px;
    position: relative;
  }
  
  .frame .overlap-group {
    height: 151px;
    left: 0;
    position: absolute;
    top: 0;
    width: 335px;
  }
  
  .frame .stable-table-row {
    background-color: #d8e0ff;
    height: 27px;
    left: 0;
    position: absolute;
    top: 0;
    width: 335px;
  }
  
  .frame .div {
    align-items: flex-start;
    display: inline-flex;
    gap: 20px;
    left: 8px;
    position: absolute;
    top: 7px;
  }
  
  .frame .div-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 22px;
    position: relative;
  }
  
  .frame .text-wrapper {
    color: var(--wwwembedded-toolsembifiinview-applicationemapl1010220000004921440x810defaultblack-87);
    font-family: "Inter-Medium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 100%;
  }
  
  .frame .div-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
  }
  
  .frame .text-wrapper-2 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: var(--wwwshastraembifiinview-applicationemapl151123000007341440x810defaultblack-russian-62);
    display: -webkit-box;
    font-family: "Inter-Regular", Helvetica;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19px;
    margin-top: -1px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
  }
  
  .frame .text-wrapper-3 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: var(--wwwshastraembifiinview-applicationemapl151123000007341440x810defaultblack-russian-62);
    display: -webkit-box;
    font-family: "Inter-Regular", Helvetica;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
  }
  
  .frame .div-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
  }
  
  .frame .div-5 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 19px;
    left: 310px;
    position: absolute;
    top: 40px;
  }
  
  .frame .edit {
    height: 12px;
    position: relative;
    width: 12px;
  }

  .table-header{
    word-break: keep-all;
  }