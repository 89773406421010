.editable-drop-input {
  border-radius: 5px !important;
  background-color: #f6f6f6 !important;
  padding: 5px 10px !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
  font-size: 14px !important;
}

.editable-select-input {
  background-color: #f6f6f6 !important;
  border-radius: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
}
.editable-select-input input {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px !important;
}

.tele-over-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(227, 227, 227, 0.647);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
