.hide-scroll-bar{
    display: grid;
    place-items: center;
    overflow: hidden;
}

.hide-scroll-bar embed::-webkit-scrollbar{
    width: 0px !important;
}
.hide-scroll-bar embed::-webkit-scrollbar-track{
    width: 0px !important;
}
.hide-scroll-bar embed::-webkit-scrollbar-thumb{
    background-color: transparent;
}