.tableContainer {
  min-height: 90vh !important;
}

.go-back-btn {
  margin-top: 20px;
  margin-left: 30px;
  font-weight: 500;
  cursor: pointer;
}

.go-back-btn-fixed {
  position: absolute;
  left: 15px;
  top: 15px;
  font-weight: 500;
  cursor: pointer;
}

.amountTxtsm {
  font-weight: 600;
  font-size: 20px;
}

.loan-table-container {
  margin: auto 0;
  height: 76vh;
  overflow: scroll;
  scroll-behavior: smooth;
}

.loan-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
  border-spacing: 0em;
}

.loan-table thead th {
  position: sticky;
  z-index: 1000;
  top: 0;
}

.loan-table thead tr th {
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.35px;
  /* color: #fff; */
  background: #dedbed;
  opacity: 1;
  padding: 0.8em 0.3em 0.8em 0.3em;
  vertical-align: top;
  border: none;
  text-align: left;
}

.loan-table tbody tr td {
  font-size: 0.8em;
  letter-spacing: 0.35px;
  padding: 5px 10px;
  font-weight: normal;
  color: #000;
  text-align: left;
  border: none;
}

.loan-tr {
  background-color: #ffffff !important;
  /* cursor: pointer; */
  border-bottom: 1pt solid rgb(204, 204, 204);
}
/* .loan-tr:nth-child(even) {
  background-color: #cfd9f4;
} */

/* Simple Table */

.loan-table-simple {
  width: 100%;
  border-collapse: collapse;
  border: none;
  border-spacing: 0em;
}

.loan-table-simple thead th {
  position: sticky;
  z-index: 1000;
  top: 0;
  box-shadow: inset 0 1px 0 #eaecf0, inset 0 -1px 0 #eaecf0;
}

.loan-table-simple thead tr th {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #667085;
  background: #f9fafb;
  opacity: 1;
  padding: 15px 20px;
  vertical-align: top;
  border: none;
  text-align: left;
}

.loan-table-simple tbody tr td {
  font-size: 0.8em;
  letter-spacing: 0.35px;
  padding: 15px 20px;
  font-weight: normal;
  color: #667085;
  text-align: left;
  border: none;
}

.loan-tr-simple {
  background-color: #ffffff !important;
  /* cursor: pointer; */
  border-bottom: 1pt solid #eaecf0;
}

.pay-btn {
  box-shadow: none !important;
  float: right !important;
  z-index: 0 !important;
  background-color: purple !important;
  border-radius: 20px !important;
  padding: 10px 20px !important;
  color: white !important;
}

.toggle-btn-holder {
  height: 30px !important;
}

.over-view-card {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 10px;
  padding: 15px 20px;
}

.over-view-card .head {
  text-transform: uppercase;
  font-size: 10px;
  color: #71717a;
  margin-bottom: 0;
}
.over-view-card .amount {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  color: #535353;
}

.modal-backdrop {
  z-index: 1201 !important;
}

.modal {
  z-index: 1202 !important;
}

.modal-content {
  top: -100px;
}

/* Sort */

.sort-dialog {
  padding: 30px !important;
}

.sort-dialog .heading {
  font-weight: 700;
  font-size: 20px;
  color: #3c3c3c;
}

.sort-bottom-holder {
  float: right;
  margin-top: 20px;
}

.sort-buttons {
  float: right !important;
}

.sort-dialog .apply-btn {
  border-radius: 20px !important;
  text-transform: none !important;
  font-weight: 400 !important;
}
.sort-dialog .clear-btn {
  text-transform: none !important;
  font-weight: 400 !important;
  color: #000000 !important;
}

.sort-table th {
  border: 1px solid #f3f3f3;
  border-collapse: collapse;
  padding: 15px 25px;
}

.sort-table td {
  border: 1px solid #f3f3f3;
  border-collapse: collapse;
}

.sort-text-field {
  padding: 5px !important;
}

.sort-close-td {
  border: 0 !important;
  padding-left: 20px;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  /* Force table to not be like tables anymore */
  #sort-table table,
  #sort-table thead,
  #sort-table tbody,
  #sort-table th,
  #sort-table td,
  #sort-table tr {
    display: block;
  }

  #sort-table {
    width: 100% !important;
  }

  .sort-table-wrapper {
    height: 350px !important;
    overflow: scroll;
    padding: 10px;
  }

  .sort-dialog {
    padding: 20px;
  }

  #sort-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #sort-table tr {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }

  #sort-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }

  #sort-table td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  /*
  Label the data
  */
  #sort-table td:before {
    content: attr(data-title);
  }

  .sort-bottom-holder {
    float: none !important;
    display: flex;
    justify-content: center !important;
  }
}

.dealer-score-holder {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 50px;
  border-radius: 30px;
}
/* .AddCommentCollection{
 border-radius: 2px solid red;

} */

.comment-delete-btn {
  background-color: red !important;
  color: white !important;
  font-size: 12px !important;
}

.generate-btn {
  box-shadow: none !important;
  z-index: 0 !important;
  border-radius: 10px !important;
  padding: 10px 20px !important;
  color: white !important;
}

.rotate-class {
  animation: animName 2s linear infinite !important;
}

.file-drag-parent {
  border: 1px dashed rgba(96, 89, 89, 1);
  background-color: rgba(248, 248, 255, 1);
  border-radius: 8px;
  height: 150px;
  width: 350px;
  cursor: pointer;
}

.doc-remove-btn {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  background-color: rgba(255, 34, 34, 1) !important;
  color: #ffffff !important;
  padding: 5px !important;
  min-width: 25px !important;
}

.doc-download-btn {
  position: absolute !important;
  right: 10px !important;
  bottom: 10px !important;
  background-color: #0d99ff !important;
  color: #ffffff !important;
  padding: 6px 8px !important;
  min-width: 25px !important;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.home-pie-holder {
  width: 100%;
  max-width: 400px; /* or any other maximum width */
  border: 1px solid black;
  border-radius: 20px !important;
  min-height: 300px !important;
}
.home-pie-holder1 {
  width: 100%;
  max-width: 100%; 
  border: 1px solid black;
  border-radius: 20px !important;
  min-height: 400px !important;
}
.home-pie-holder1 canvas,
.home-pie-holder canvas {
  width: 100% !important;
  height: auto !important;
}

.home-pie-heading {
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 20px;
  font-weight: 600;
  font-size: 16px;
}

.pie-label {
  display: flex;
  font-size: 10px;
  width: 100% !important;
}
.pie-label .color-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 9px !important;
  width: 9px !important;
  margin-right: 5px;
  border-radius: 2px;
}
.total-count {
  
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

/* Notifications */

.notification-card {
  width: 100%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 15px;
}

.notification-card .noti-heading {
  margin-bottom: 10px;
  color: #77747b;
  font-size: 20px;
  font-weight: 600;
}

.notification-card .noti-body {
  margin-bottom: 25px;
}

.notification-card .noti-time {
  margin-bottom: 0;
  color: #88848c;
}

.notification-not-read {
  box-shadow: rgba(146, 14, 14, 0.285) 0px 4px 12px;
}

.noti-pop-up .noti-heading {
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .noti-pop-up .right-content {
    padding-left: 0 !important;
  }
}

strong {
  font-weight: 600;
}

.notification {
  width: 360px;
  padding: 15px;
  background-color: white;
  border-radius: 16px;
  position: fixed;
  top: -600px;
  right: 15px;
  z-index: 10000;
  transition: all 0.5s ease-in-out;
  animation: noti 2s infinite forwards alternate ease-in;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.notification-title {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.notification-close {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
  font-size: 14px;
}

.notification-container {
  display: flex;
  align-items: flex-start;
}

.notification-media {
  position: relative;
}

.notification-user-avatar {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  object-fit: cover;
}

.notification-reaction {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  color: white;
  background-image: linear-gradient(45deg, #0070e1, #14abfe);
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.notification-content {
  width: calc(100% - 60px);
  padding-left: 20px;
  line-height: 1.2;
}

.notification-text {
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 50px;
}

.notification-timer {
  color: #1876f2;
  font-weight: 600;
  font-size: 14px;
}

.notification-status {
  position: absolute;
  right: 15px;
  top: 50%;
  width: 15px;
  height: 15px;
  background-color: #1876f2;
  border-radius: 50%;
}

.noti-popup-open {
  top: 15px !important;
}

.zoom-animation {
  animation: zoomInOut 1s ease-in-out infinite alternate;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.33); /* Increase size by 33% */
  }
  100% {
    transform: scale(1);
  }
}

.agent-current-header{
  font-weight: 500;
  font-size: 18px;
}

.agent-calender .react-calendar {
  border: none !important; /* Change the border color as per your preference */
  border-radius: 20px !important;
}

.agent-calender abbr {
  display: inline-block;
  height: 30px; /* Adjust the height as needed */
  width: 30px; /* Adjust the width as needed */
  text-align: center;
  line-height: 20px; /* Ensure the text is vertically centered */
  padding-top: 5px;
}

.visited-dates abbr {
  color: rgb(255, 255, 255) !important;
  background-color: #008000 !important;
  border-radius: 50%;
}

.non-visited-dates abbr {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(170, 14, 14) !important;
  border-radius: 50%;
}

.agent-left-content {
  display: flex;
  justify-content: center;
}

.agent-visit-card {
  padding: 10px !important;
  margin: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 4px !important;
}

.agent-visit-card iframe {
  pointer-events: none;
  height: 100% !important;
}

.agent-visit-card .frame-holder {
  width: 100%;
  background: #000;
  border-radius: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agent-dep-chip{
  font-size: 10px !important;
  padding: 3px 7px;
  background: rgb(227, 227, 227);
  border-radius: 10px;
  margin-right: 2px;
}
.slide-in {
  animation: slide-in 0.5s ease-in-out forwards;
}
.slide-out {
  animation: slide-out 0.5s ease-in-out forwards;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(-50%); /* Adjust horizontal slide distance */
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    opacity: 0;
    transform: translateX(50%); /* Adjust horizontal slide distance */
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.view-target-header-container {
  padding: 15px 10px;
}

.view-target-header-title {
  font-size: 20.68px;
  font-family: 'Inter';
  font-weight: 400;
  word-wrap: break-word;
}

.view-target-header-info {
  margin-bottom: 10px;
  font-size: 16.54px;
  font-family: 'Roboto';
  font-weight: 500;
  line-height: 17.48px;
  word-wrap: break-word;
}

.view-target-header-progress-bar-container {
  width: 100%;
  height: 16.54px;
  position: relative;
}

.view-target-header-progress-bar-bg {
  width: 100%;
  height: 16.54px;
  position: absolute;
  background: #D2D2D2;
  border-radius: 34.30px;
  left: 0;
  top: -2.07px;
}

.view-target-header-progress-bar-fill {
  height: 16.54px;
  position: absolute;
  border-radius: 34.30px;
  left: 0;
  top: -2.07px;
}

.view-target-header-progress-bar-label {
  width: 52.11px;
  height: 16.54px;
  position: absolute;
  border-radius: 28.95px;
  justify-content: center;
  align-items: center;
  gap: 20.68px;
  display: inline-flex;
  top: -2.07px;
}

.view-target-header-progress-bar-label-wrapper {
  width: 100%;
  height: 100%;
  padding-left: 8.27px;
  padding-right: 8.27px;
  position: absolute;
  background: rgba(244, 244, 244, 0.60);
  border-radius: 28.95px;
  justify-content: center;
  align-items: center;
  gap: 20.68px;
  display: inline-flex;
}

.view-target-header-progress-bar-label-text {
  color: #1C1C1C;
  font-size: 12.41px;
  font-family: 'Roboto';
  font-weight: 700;
  line-height: 33.08px;
  word-wrap: break-word;
}