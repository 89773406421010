.upload-btn-anchor {
  width: 100% !important;
  height: 90px !important;
  text-transform: none !important;
  box-shadow: none !important ;
  font-weight: 400 !important;
  border-radius: 10px !important;
}

.supporting-text-cont-anchor {
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
}

.supporting-text-anchor {
  color: #808080;
  font-size: 16px;
  font-weight: 400;
}

.anchor-doc-preview {
  width: 100%;
  height: 90px;
  background-color: black;
  border-radius: 10px;
  overflow: hidden;
}
.anchor-doc-preview embed {
  width: 100%;
  height: 100%;
}

.file-remove-btn {
  position: absolute !important;
  right: 5px !important;
  top: 20px !important;
  background-color: black !important;
  color: red !important;
  padding: 0 !important;
  min-width: 25px !important;
}

.file-download-btn {
  position: absolute !important;
  right: 5px !important;
  bottom: 5px !important;
  background-color: #2c71c2 !important;
  color: #fff !important;
  padding: 2px 0px !important;
  min-width: 25px !important;
}

.other-doc-download-btn {
  position: absolute !important;
  right: 5px !important;
  top: 80px !important;
  background-color: #2c71c2 !important;
  color: #fff !important;
  padding: 2px 0px !important;
  min-width: 25px !important;
}

.remove-aadhaar {
  left: 10px !important;
  right: auto !important;
}

.add-more-disabled {
  opacity: 1 !important;
}

.add-more-aadhaar {
  width: 75% !important;
  position: absolute !important;
  right: 0 !important;
}
.file-remove-btn svg {
  width: 18px !important;
}

.upload-section-heading {
  color: gray;
}

.remove-oem-btn {
  /* position: absolute !important;
  top: 10px !important;
  right: 10px !important; */
  font-size: 12px !important;
  color: #484848 !important;
  z-index: 1000 !important;
}

.add-rm-btn {
  background: #484848 !important;
  font-weight: 400 !important;
  color: white !important;
  text-transform: none !important;
  margin-top: 20px !important;
}

.close-btn-rm {
  color: #484848 !important;
}

.anchor-doc-error {
  color: red !important;
  font-size: 10px !important;
}

.update-anchor-wrapper .update-anchor-sidebar {
  /* background: rgb(5, 68, 104); */
  position: fixed;
  top: 0;
  left: 78px;
  width: 300px;
  height: 100%;
  padding: 20px 15px;
  transition: all 0.5s ease;
}

.update-anchor-wrapper .section {
  display: flex;
  justify-content: center;
  width: calc(100% - 300px) !important;
  height: 100vh !important;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  /* background-color: red !important; */
  left: 320px;
  transition: all 0.5s ease;
  padding: 20px;
}

.update-anchor-wrapper .section .steps-holder {
  width: 90% !important;
  padding: 20px !important;
  margin-bottom: 50px !important;
}

.anchor-update-btn {
  margin-top: 30px !important;
  padding: 15px 10px !important;
  border-radius: 30px !important;
  box-shadow: none !important;
}
