@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.dialog-paper {
    font-family: 'Manrope', sans-serif;
    font-weight: extralight;
}

.custom-table-cell {
    border: none;
}

.custom-table-cell.even {
    background-color: white;
}

.custom-table-cell.odd {
    background-color: #f8f7ff;
}

.custom-table-header-cell {
    border-right: 1px solid #e0e0e0;
    /* Adjust color as needed */
    padding: 8px 16px;
    font-size: 0.875rem;
}

.search-bar-table me-5 {
    margin-left: 100px;
}