.kyc-section-card {
  position: relative;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.section-heading {
  font-weight: 500;
  font-size: 20px !important;
  text-transform: uppercase;
}

.audio-btns {
  color: black !important;
  font-size: 10px !important;
  padding: 5px !important;
  min-width: 0 !important;
}

.kyc-video-capture-btn {
  font-size: 13px !important;
}

.comment-btns {
  font-size: 13px !important;
  text-transform: none !important;
}

.approve-all-btn {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}
