.addhaar-wrapper {
  display: flex;
  justify-content: center;
}

.content-center {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  object-fit: contain;
}

.ReactCrop{
    max-width: 700px;
    max-height: 500px;
    position: relative;
}

.ReactCrop__image {
    width: 100% !important;
    max-height: 500px;
}

.pointer {
  cursor: pointer;
}

.fixed-wrapper {
  width: 100%;
  height: 100vh;
}

.left-panel {
  padding: 20px;
}

.right-panel {
  height: 100vh;
  overflow-y: scroll;
}

.left-panel {
  height: 100vh;
  overflow-y: scroll;
}

.response-table {
  background-color: rgb(228, 228, 228);
  height: auto;
  padding: 40px;
  border-radius: 10px;
}

.right-panel .heading {
  font-weight: 600;
  font-size: 20px;
}

.parse-btn {
  width: 100%;
  box-shadow: none !important;
  height: 50px !important;
  border-radius: 20px !important;
}

.error-border{
    border: 2px solid red;
}

.success-border{
    border: 2px solid rgb(0, 255, 47);
}

