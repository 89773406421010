.dash-stat-holder {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 20px !important;
  padding: 10px !important;
}

.gps-navbar {
  position: fixed;
  left: 0;
  right: 0;
  height: 50px;
  background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gps-dash-card {
  height: 109px !important;
  border-radius: 8px !important;
  border: 0.8px solid #dedede;
  display: flex;
  padding: 20px !important;
  gap: 10px !important;
}

.gps-dash-card .count {
  margin-bottom: 0 !important;
  font-size: 20px;
  font-weight: 500;
}

.gps-dash-card .desc {
  margin-bottom: 0 !important;
  font-size: 14px !important;
  font-weight: 500;
}

.gps-donut-holder {
  position: relative;
  width: 100%;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px !important;
  padding: 20px !important;
}

.gps-donut-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.gps-donut-label .mmi-span {
  height: 10px;
  width: 10px;
  background: #ec4899;
  border-radius: 50% !important;
}

.gps-donut-label .mufin-span {
  height: 10px;
  width: 10px;
  background: #14b8a6;
  border-radius: 50% !important;
}

/* Toggle */

.toggleContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
  /* border: 3px solid #343434; */
  border-radius: 20px;
  background: #dedede;
  font-weight: 500;
  color: red;
  cursor: pointer;
}
.toggleContainer::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius: 20px;
  /* background: white; */
  background: rgb(236, 72, 111);
  background: linear-gradient(
    90deg,
    rgba(236, 72, 111, 1) 59%,
    rgba(224, 26, 230, 1) 100%
  );
  transition: all 0.3s;
}
.toggleCheckbox:checked + .toggleContainer::before {
  left: 50%;
  background: #14b8a6;
  background: linear-gradient(90deg, #14b8a6 59%, #0e9182 100%) !important;
}
.toggleContainer div {
  padding: 6px;
  text-align: center;
  z-index: 1;
}
.toggleCheckbox {
  display: none;
}
.toggleCheckbox:checked + .toggleContainer div:first-child {
  color: #4a4a4a;
  transition: color 0.3s;
}
.toggleCheckbox:checked + .toggleContainer div:last-child {
  color: white;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:first-child {
  color: white;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:last-child {
  color: #4a4a4a;
  transition: color 0.3s;
}

.gps-loader-div {
  min-height: 300px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px !important;
  padding: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gps-card-last-updated {
  display: flex;
  justify-content: end;
  margin-top: -10px;
  color: gray;
  font-size: 13px;
  font-weight: 500;
}

.movement-table-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.movement-filter-btn {
  position: absolute;
  right: 10px;
}

.tab-menu-item-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.gps-daily-trend-card {
  padding: 20px !important;
}

.gps-daily-trend-card .header-data {
  display: flex;
  justify-content: space-between;
}

.gps-daily-trend-card .header-data .round-span {
  height: 10px;
  width: 10px;
  background: gray;
  border-radius: 50% !important;
}
