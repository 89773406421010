input#session-date {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.contacts-table {
  table-layout: fixed;
  width: 100%;
}
.contacts-table th,
.contacts-table td {
  word-wrap: break-word;
  white-space: normal;
  vertical-align: middle;
  border: none !important;
}

.contacts-table th {
  font-size: 12px !important;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.508);
  position: sticky;
  top: 0; /* Stick the header to the top of the scrollable area */
  z-index: 1000 !important;
  background-color: #eff5ff !important;
}

.contacts-table .relation-span {
  font-size: 11.5px !important;
  background-color: #f0f1fa;
  padding: 3px 10px !important;
  color: #4f5aed;
  border-radius: 20px;
}

.contact-heading {
  margin-bottom: 0 !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.input-style2 {
  background-color: #ffffff !important;
  border-radius: 2px !important;
}

.telepd-switch-container button {
  padding: 3px 20px !important;
  font-size: 12px !important;
  margin-right: 8px !important;
}

.otp-input-fields {
  margin-top: 20px;
  background-color: white;
  /* max-width: 400px; */
  width: auto;
  display: flex;
  justify-content: start;
  gap: 20px;
  /* padding: 40px; */
}

.otp-input-fields input {
  height: 70px;
  width: 70px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #a8a8a8;
  text-align: center;
  outline: none;
  font-size: 24px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &:focus {
    border-width: 2px;
    border-color: darken(#a8a8a8, 5%);
    font-size: 20px;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #1a1a1a;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 70px;
    left: 70px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 70px;
    left: 70px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 70px;
    left: 70px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 145px;
    height: 145px;
    opacity: 0;
  }
}

/* Chat history  */
.chat-holder {
  min-width: 800px;
  min-height: 50vh;
}

.chat-bubble-holder {
  display: flex;
}

.chat-bubble {
  max-width: 500px;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.chat-sent {
  justify-content: end;
}

.chat-bubble-sent {
  justify-content: end;
  background: #e0fec6;
  border-radius: 10px 10px 0 10px;
}

.chat-bubble-receive {
  justify-content: end;
  background: #ffffff;
  border-radius: 10px 10px 10px 0;
}

.chat-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(198, 198, 198);
  padding: 10px;
  cursor: pointer;
}

.chat-meta {
  display: flex;
  justify-content: end;
  padding: 0 10px !important;
  font-size: 12px;
  color: rgb(138, 138, 138);
}

.context-holder {
  padding: 10px;
  padding-top: 5px;
  margin: 10px !important;
  border-radius: 10px !important;
  background: rgb(237, 234, 234);
}

.context-header {
  color: red !important;
}

.chat-date-divider {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.chat-date-divider span {
  background: #d7eaf4;
  color: #434343;
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 5px;
}

.chat-empty-div {
  background: #d7eaf4;
  color: #434343;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 5px;
}

.attach-doc-holder embed::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari, etc.) */
}
.attach-doc-holder embed {
  overflow: hidden !important;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.attach-doc-holder {
  overflow: hidden !important;
}

.notifications-header {
  position: fixed;
  top: 0;
  background-color: white;
  width: 83% !important;
  display: flex;
  align-items: center;
  padding: 20px !important;
  height: 70px !important;
  z-index: 5000;
  justify-content: space-between;
}

.upload-section-do label {
  max-width: unset;
}

.dialog-container {
  position: relative;
  height: 800px;
  width: 100%;
}

.dialog-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.input-container {
  display: flex;
  align-items: center;
  /* flex-wrap: nowrap; */
  flex: 1;
  overflow: hidden;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
}

.input-wrapper {
  flex: 1; /* Adjust flex value to give more space */
  min-width: 50px;
}

.input-wrapper.search-input {
  flex: 3; /* Allocate more space for the search bar */
  min-width: 50px;
}

.input-wrapper.small-input {
  flex: 1;
  width: 20px;
  margin-right: 10px;
}

.dialog-content {
  height: calc(100% - 60px);
  overflow: hidden;
  position: relative;
}

.map-container {
  width: 100%;
  height: 100%;
}

.loading-overlay,
.gps-loading-overlay {
  height: 100%;
  width: 100%;
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.gps-loading-overlay {
  bottom: 0;
  left: 0;
  padding: 16px;
  background: rgba(0, 0, 0, 0.75);
}
