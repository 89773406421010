.vehicle-doc-uploader {
  width: 100% !important;
  border: 2px dashed rgb(192, 192, 192);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  border-radius: 10px !important;
  cursor: pointer;
}
.vehicle-doc-uploader .doc-title {
  font-size: 13px !important;
  margin-bottom: 0 !important;
}

.confis-head {
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.confisc-doc-preview {
  width: 100%;
  height: 120px;
  background-color: rgb(42, 42, 42);
  border-radius: 10px;
  overflow: hidden !important;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important;
}
.confisc-doc-preview::-webkit-scrollbar {
  display: none;
}

.confisc-doc-preview .object-normal {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.confisc-doc-preview .object-pdf {
  margin-top: -30px;
  margin-left: -15px;
  width: 130%;
  height: 150%;
  overflow: hidden !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.confisc-doc-preview object::-webkit-scrollbar {
  display: none;
}

.confisc-file-remove-btn {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
  background-color: black !important;
  color: red !important;
  padding: 0 !important;
  min-width: 25px !important;
  z-index: 4500 !important;
}

.green-btn {
  background-color: forestgreen !important;
}

.confisc-button-holder {
  position: absolute !important;
  bottom: 5px;
  left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.confisc-button-holder div {
  display: flex;
  justify-content: center;
  background-color: red;
  color: white;
  cursor: pointer;
}

.confisc-button-holder .view-btn {
  background-color: red;
  border-radius: 0 0 0 10px;
}
.confisc-button-holder .download-btn {
  background-color: #00000061;
  border-radius: 5px;
  padding: 5px;
}

.conf-preview {
  width: 100%;
  height: 500px !important;
  background-color: #262626;
}

.confisc-dailog-content {
  overflow: hidden !important;
  position: relative !important;
}

.conf-preview {
  height: 500px !important;
}

.conf-preview embed {
  width: 100%;
  height: 100%;
}

.conf-view-arrow {
  background-color: white !important;
  z-index: 5000 !important;
  top: 50%;
  transform: translate(-50%, -50%);
}

.conf-view-arrow.right {
  position: absolute !important;
  right: 0px !important;
}
.conf-view-arrow.left {
  position: absolute !important;
  left: 40px !important;
}

.conf-prev-download {
  position: absolute !important;
  right: 10px !important;
  bottom: 10px !important;
  z-index: 8000 !important;
  background-color: blue !important;
  color: white !important;
  text-decoration: none !important;
  border-radius: 30px !important;
  font-size: 12px !important;
}

.hover-div-btn {
  width: 100%;
  height: 100%;
  position: absolute;
  content: " ";
}

.play-btn {
  position: absolute !important;
  color: red !important;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px !important;
  opacity: 0.7;
}

.comments-edited-tag {
  display: inline-block;
  background: rgba(102, 102, 102, 0.608);
  color: white;
  font-size: 8px !important;
  padding: 5px 7px;
  border-radius: 7px !important;
  margin-bottom: 0 !important;
  position: absolute;
  right: 0 !important;
}

input#session-date {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}


.comments-table {
  table-layout: fixed;
  width: 100%; /* You can adjust this width as needed */
}

.comments-table th, .comments-table td {
  word-wrap: break-word; /* Allow long words to wrap within cells */
  white-space: normal; /* Prevent content from overflowing */
}

.comments-table td pre {
  white-space: pre-wrap;
  overflow: auto;
}
