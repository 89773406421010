body {
  background-color: #f7f7f7;
}

.sidebar {
  margin: 0;
  padding: 10px 20px;
  width: 300px;
  /* background-color: #ffffff; */
  background: #11101d;
  position: fixed;
  height: 100%;
  color: #ffffff;
  overflow: auto;
  transition: width 0.3s ease-out;
  background-position: left 0 bottom 0;
  background-repeat: no-repeat;
  background-size: 310px 556px;
}

.sidebar-content{
  transition: all 0.3s ease-out;
}

.customer-info-list{
  background: #34333e59;
  padding: 8px;
  border-radius: 15px;
}

.list-item-line{
  border-bottom: 1px solid #ffffff12;
  padding: 2px 0px;
}

div.content {
  margin-left: 300px;
  transition: margin-left 0.3s ease-out;
}

.customer-name {
  font-weight: 400;
  font-size: 16px;
  word-wrap: break-word;
  padding-right: 10px;
  margin-bottom: 0;
}

.customer-id{
  font-size: 13px;
}

.expand-btn{
  position: absolute;
  bottom: 5%;
  right: 0;
  background: #f7f7f7;
  border: none;
  border-radius: 20px 0 0 20px;
  height: 40px;
  color: #ffffff9e;
  cursor: pointer;
  z-index: 1000;
}

.arrow-icon{
  transition: all 0.3s ease-in-out;
  color: #0101109e !important;
  font-size: 18px !important;
}

.profile-tab {
  text-transform: none;
}

.tab-div{
  font-size: 11px !important;
}

.list-icon {
  min-width: 35px !important;
  align-self: flex-start;
  margin-top: 5px;
  color: gray !important;
}
.list-key {
  width: 100px !important;
  align-self: flex-start;
  margin-top: 5px;
  color: rgb(137, 137, 137);
  font-size: 12.2px;
  margin-right: 10px;
}

.list-key-big {
  width: 200px !important;
}

.loan {
  width: 150px !important;
}

.list-value {
  word-wrap: break-word;
  color: black !important;
  font-size: 12px !important;
}

.overview-card {
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: #ffffff;
}

.card-heading {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid rgb(218, 218, 218) !important;
  color: gray;
  font-size: 13px;
  margin-bottom: 0;
}

.sub-heading {
  color: rgb(128, 17, 130);
  font-size: 13px;
}

.card-content {
  padding: 16px;
}

.edit-btn {
  width: 100%;
  /* border: 1px solid rgb(192, 192, 192) !important; */
  margin-top: 20px !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 200 !important;
  background: #49485e !important;
  font-size: 11px !important;
  padding: 10px 0px !important;
  border-radius: 20px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.edit-btn .edit-icon {
  font-size: 16px;
  margin-right: 10px;
}

.doc-btns {
  font-size: 14px !important;
  margin-left: 25px;
  color: #4d19d2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-thumbnail{
  position: relative;
}

.icons{
  position: absolute;
  top: 0;
  right: 0;
  background: #25a751;
  border-radius: 50%;
  padding: 8px;
  color: white;
  font-size: 8px !important;
  border: 3px solid white;
  z-index: 1;
}

.approved-icon{
  position: absolute;
  top: 0;
  right: 50px;
  background: #25a751;
  border-radius: 50%;
  padding: 5px;
  color: white;
  font-size: 8px !important;
  border: 3px solid white;
}
.rejected-icon{
  position: absolute;
  top: 0;
  right: 50px;
  background: #a72530;
  border-radius: 50%;
  padding: 5px;
  color: white;
  font-size: 8px !important;
  border: 3px solid white;
}

.money-recieved-btn{
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  padding:  5px 10px !important
}

.money-recieved-btn.active{
  background-color: #00C643 !important;
}