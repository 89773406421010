.wrapper-center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calculate-form {
  width: 50%;
  padding: 50px;
  padding-right: 60px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  border-radius: 30px;
}

.payment-form {
  width: 70%;
  padding: 50px;
  height: auto !important;
  padding-right: 60px;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  border-radius: 30px;
}

.calculate-btn {
  box-shadow: none !important;
  float: right !important;
  z-index: 0 !important;
  background-color: purple !important;
  border-radius: 20px !important;
  padding: 10px 20px !important;
  color: white !important;
}

.date-picker-show {
  width: 100%;
  height: 100vh;
  left: 0 !important;
  top: 0 !important ;
  position: fixed;
  z-index: 5000 !important;
}

.emi-btn {
  padding: 10px 15px;
  background: white;
  border: 1px solid #dedede;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}
.emi-text {
  /* color: #6c6c6c; */
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  word-wrap: break-word;
}
