.view-card-container {
  display: flex;
  align-items: center;
}

.view-card {
  /* background-color: rgb(234, 230, 230); */
  /* border-radius: 5px; */
  /* padding: 0.2em 0.4em; */
  font-size: 1.1rem;
  width: 70%;
  margin-bottom: 0.5em;
  border-bottom: 1px solid grey;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

/*----------------------------------------*/

.drop-container {
  position: relative;
  /* display: flex; */
  /* gap: 10px; */
  /* flex-direction: column; */
  text-align: center;
  justify-content: center;
  /* align-items: center; */
  /* height: 200px; */
  margin-top: 20px;
  width: 100%;
  /* padding: 20px; */
  border-radius: 10px;

  border: 2px dotted rgb(233, 231, 231);
  color: #444;
  /* cursor: pointer; */
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: rgb(247, 247, 247);
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  margin-top: 30px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/* edit collection */
.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  border: 1px solid rgb(137, 137, 137);
  ;
  height: 5em;
  width: 5em;
}

.removeIcon {
  width: 1em;
  height: 1em;
  position: absolute;
  top: 0.2em;
  right: 0.2em;
  cursor: pointer;
}

.file-upload-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 5em;
  width: 5em;
}

.file-upload-label {
  color: rgb(137, 137, 137);
}

/* penny drop failed logs */
.pl-container {
  border: 1px solid rgb(137, 137, 137);
  border-radius: 10px;
  padding: 0.5em;
}

.pl-label {
  margin-bottom: 0;
  color: rgb(137, 137, 137);
  font-size: 15px;
  width: 7em;
}

.pl-label-2 {
  margin-bottom: 0;
  color: #000;
  font-size: 15px;
}

.pl-box {
  display: flex;
  align-items: center;
}

/* add transaction */
.at-title {
  font-size: 1.4em;
}

.at-input {
  outline: none;
  padding: 0.5em;
  border-radius: 10px;
  border: 1px solid #000;
}

/* documents */
.reject-reason {
  width: 100%;
  height: 9em;
  outline: none;
}

.reject-btn {
  background: #F55656;
  border-radius: 4px;
  color: 000;
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 0.3em 1em;
  margin-top: -1.8em;
  margin-right: 1em;
}

/* document approve/reject dropdown */
.doc-status-dropdown {
  outline: none;
  border: none;
  background: 'transparent';
  color: #fff;
}

.doc-status-dropdown option {
  background-color: grey;
}